import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-advantage',
  templateUrl: './account-advantage.component.html',
  styleUrls: ['./account-advantage.component.scss'],
})
export class AccountAdvantageComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
