import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PopoverController, NavParams, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  @Output() _anEmitter = new EventEmitter();
  route: string;
  deleteRoute: string;
  itemsSelected: any = [];
  items: any = [];

  constructor(public popoverCtrl: PopoverController, private navParams: NavParams, private router: Router, public _commonService: CommonService, private _apiService: ApiService, private alertController: AlertController) {
    this._anEmitter = navParams.data.emitter;
  }

  ngOnInit() {
    this.route = this.navParams.data.route;
    this.itemsSelected = this.navParams.data.itemsSelected;
    this.items = this.navParams.data.items;
    this.deleteRoute = this.navParams.data.deleteRoute
  }

  async dismiss() {
    await this.popoverCtrl.dismiss();
  }

  gotoRoute(){
    this.router.navigate([this.route]);
  }

  delete(){
    this.presentAlertConfirm();
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: this._commonService.getLocalTranslation('label-pleaseConfirm'),
        message: this._commonService.getLocalTranslation('label-deleteItems'),
      buttons: [
        {
          text: this._commonService.getLocalTranslation('label-no'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this._commonService.getLocalTranslation('label-yes'),
          handler: async () => {
            this._commonService.presentLoading();
            try {
              this.emitEvent(true);
              this._commonService.dismiss();
            } catch (error) {
              this._commonService.dismiss();
              this._commonService.log(error, this._commonService.ERROR);
            }
          }
        }]
    });
    await alert.present();
  }

  private emitEvent(status: any) {
    this._anEmitter.emit(status);
  }
    
}