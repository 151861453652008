import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-secondary-toolbar',
  templateUrl: './secondary-toolbar.component.html',
  styleUrls: ['./secondary-toolbar.component.scss'],
})
export class SecondaryToolbarComponent implements OnInit, OnChanges {
  @ViewChild('searchFocus', {static: false}) ionInput: { setFocus: () => void; };
  @Input() name: any;
  @Input() childName: any;
  @Input() title: any;
  @Input() subtitle: any;
  @Input() showAdd: boolean = false;
  @Input() showSave: boolean = false;
  @Input() showDelete: boolean = false;
  @Input() showSearch: boolean = false;
  @Input() showBack: boolean = false;
  @Input() showUpload: boolean = false;
  @Input() showDownload: boolean = false;
  @Input() showSaveReturn: boolean = false;
  @Input() selectedItem: any;
  @Input() backButtonRoute: string = '';
  @Input() disableSave: boolean = false;
  @Input() showSend: boolean = false;
  @Input() showFilter: boolean = false;
  @Input() showExport: boolean = false;
  @Input() disabledSave: boolean

  searchText: string = '';
  showSearchContainer: boolean = false;

  @Output() performAction = new EventEmitter<any>(true);

  constructor(public popoverController: PopoverController, private router: Router,) { }

  ngOnInit() {
    this.searchText = '';
    if(this.title && this.title.length > 20) {
      this.title = this.title.substring(0,20) + "...";
    }
    if(this.subtitle && this.subtitle.length > 20) {
      this.title = this.subtitle.substring(0,20) + "...";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
      if(changes.title && changes.title.currentValue && changes.title.currentValue.length > 20) {
        this.title = changes.title.currentValue.substring(0,20) + "...";
      }
      if(changes.subtitle && changes.subtitle.currentValue && changes.subtitle.currentValue.length > 20) {
        this.subtitle = changes.subtitle.currentValue.substring(0,20) + "...";
      }
  }

  ngOnDestroy(): void {
  }

  triggerEvent(action, event) {
    let obj = {
      Action: action,
      Event: event
    }
    this.performAction.emit(obj);
  }

  triggerCloseEvent(action, event) {
    if(event != '') {
      let obj = {
        Action: action,
        Event: event
      }
      this.performAction.emit(obj);
    }
    else {
      this.showSearchContainer = false
    }

  }

  autofocusInput() {
    this.ionInput.setFocus();
    // setTimeout(() => {
    //   this.test.nativeElement.firstChild['autofocus'] = 'true';
    // }, 100);
  }

  // goBack(){
  //   this.router.navigate(['/' + this.backButtonRoute]);
  // }
//
}
