import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SecondaryToolbarModule } from './components/secondary-toolbar/secondary-toolbar.module';
import { SearchpopoverComponent } from './components/searchpopover/searchpopover.component';
import { PopoverComponent } from './components/popover/popover.component';

import { RouterModule } from '@angular/router';
import { NumberDirective } from './custom.directive';
import { NgxSummernoteModule } from 'ngx-summernote';
import { DisableControlDirective } from './disable-input.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DateFilterPipe } from './pipes/dateFilter.pipe';
import { FaqComponent } from './components/faq/faq.component';
import { NeedHelpComponent } from './components/need-help/need-help.component';
import { AccountAdvantageComponent } from '../auth/registration/account-advantage/account-advantage.component';
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component';
import { NumbersOnlyDirective } from 'src/app/directives/numbers-only.directive';
import { IntegersOnlyDirective } from 'src/app/directives/integers-only.directive';
import { EuroCurrencyDirective } from 'src/app/directives/eurocurrency.directive';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import {NgxPopperModule} from 'ngx-popper';
import {MatMenuModule} from '@angular/material/menu';
import { AppPaginationComponent } from './components/app-pagination/app-pagination.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { QuickscanResultComponent } from './components/quickscan-result/quickscan-result.component';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { AddContractcompanyComponent } from './components/add-contractcompany/add-contractcompany.component';
import { AddContractpersonComponent } from './components/add-contractperson/add-contractperson.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl  } from '@danielmoncada/angular-datetime-picker';
import { DateTimeIntl } from 'src/app/shared/classes/dateTimeIntl';
import { DatePipe } from '@angular/common';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { PdfviewerComponent } from './components/pdfviewer/pdfviewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { IonicSelectableComponent, IonicSelectableHeaderTemplateDirective, IonicSelectableFooterTemplateDirective, IonicSelectableSearchFailTemplateDirective, IonicSelectableItemTemplateDirective,  } from 'ionic-selectable';


@NgModule({
  declarations: [

    PopoverComponent,
    NumberDirective,
    DisableControlDirective,
    FileSizePipe,
    DateFilterPipe,
    NeedHelpComponent,
    FaqComponent,
    QuickscanResultComponent,
    AppPaginationComponent,
    SearchpopoverComponent,
    AccountAdvantageComponent,
    CircularProgressComponent,
    NumbersOnlyDirective,
    IntegersOnlyDirective,
    EuroCurrencyDirective,
    AddCompanyComponent,
    PdfviewerComponent,
    AddContractcompanyComponent,
    AddContractpersonComponent,
  ],
  entryComponents: [
    SearchpopoverComponent,
    PopoverComponent,
    AddCompanyComponent,
    PdfviewerComponent,
    AddContractcompanyComponent,
    AddContractpersonComponent,
  ],
  imports: [
    IonicSelectableItemTemplateDirective,
    IonicSelectableSearchFailTemplateDirective,
    IonicSelectableFooterTemplateDirective,
    IonicSelectableHeaderTemplateDirective,
    IonicSelectableComponent,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    PdfViewerModule,
    NgxSummernoteModule,
    NgxSmartModalModule,
    MatSelectModule,
    MatMenuModule,
    DragDropModule,
    NgxMatSelectSearchModule,
    NgxPopperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxDatatableModule,
  ],
  exports: [
    IonicSelectableItemTemplateDirective,
    IonicSelectableSearchFailTemplateDirective,
    IonicSelectableFooterTemplateDirective,
    IonicSelectableHeaderTemplateDirective,
    IonicSelectableComponent,
    IonicModule,
    TranslateModule,
    CommonModule,
     PdfviewerComponent,
    FormsModule,
    DragDropModule,
    PdfViewerModule,
    NgxSmartModalModule,
    ReactiveFormsModule,
    SecondaryToolbarModule,
    SearchpopoverComponent,
    PopoverComponent,
    AppPaginationComponent,
    NumberDirective,
    NgxSummernoteModule,
    DisableControlDirective,
    FileSizePipe,
    DateFilterPipe,
    NeedHelpComponent,
    FaqComponent,
    QuickscanResultComponent,
    NumbersOnlyDirective,
    IntegersOnlyDirective,
    EuroCurrencyDirective,
    AccountAdvantageComponent,
    CircularProgressComponent,
    MatSelectModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    NgxPopperModule,
    AddCompanyComponent,
    AddContractcompanyComponent,
    AddContractpersonComponent,
  ],
  providers: [DatePipe,{ provide: OwlDateTimeIntl, useClass: DateTimeIntl },DateFilterPipe]
})
export class SharedModule { }
