/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit,AfterViewInit ,  ViewChild, HostListener, ElementRef, HostBinding } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { MenuController } from '@ionic/angular';

import { AuthService } from './services/auth.service';
import { CommonService } from './services/common.service';
import { IonSelect } from '@ionic/angular';
import { TranslationService } from './services/translation.service';
import { ApiService } from './services/api.service';
import { HttpClient } from '@angular/common/http';
import { MainService } from './services/main.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { CategoriesPage } from './maintenance/faqs/categories/categories.page';
// import { MatomoInjector } from 'ngx-matomo';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    trigger(
      'loadingAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms', style({ opacity: 0 }))
      ])
    ]
    ),
    trigger(
      'alertMessageAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms', style({ opacity: 0 }))
      ])
    ]
    )
  ],
})

export class AppComponent implements OnInit, AfterViewInit  {
  @ViewChild('focusAlert') focusAlert: ElementRef;
  @ViewChild('f', { read: NgForm }) form: any;
  @ViewChild('rightNav') rightNav: MatDrawer;
  @ViewChild('cont') contEl: any;
  callEventLargeScreen = true;
  callEventSmallScreen = true;
  hasBackdrop = false;
  testing = false;
  knowledgeIconClicked = false;
  firstName: any = '';
  roleId: any = '';
  ktStack = [];
  email: any = '';
  submitted = false;
  expandRightNavBar = false;
  isFromRegisterLink = false;
  controlStatus: any = {
    Email: false,

  };
  checkPosition = 0;

  disableRoleSelection = false;
  domainId: string;
  allRoles = [];


  //matomoURL: string;
  //matomoAppId : string;

  defaultHelpDetails = {
    Email: 'info@rechtdirect.nl',
    HelpText: 'Neem contact met ons op!',
    Phone: '085-0081180'
  };
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    const firstInput: any = document.querySelector('ion-alert input');
    if (this._commonService.showAlert && firstInput) {
      this._commonService.dismissAlert();
    }
  }


  @HostBinding('@.disabled')
  animationsDisabled = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 800) {
      if (this.callEventLargeScreen) {
        this._commonService.onResize.emit('resize');
        this.callEventLargeScreen = false;
        this.callEventSmallScreen = true;
      }
      this._commonService.showSideMenu = true;
      this._commonService.customActivityListSelectOptions.cssClass = 'custom-select-interface';
      this._commonService.customActivityListUrgencySelectOptions.cssClass = 'custom-select-interface';
    } else {
      if (this.callEventSmallScreen) {
        this._commonService.onResize.emit('resize');
        this.callEventSmallScreen = false;
        this.callEventLargeScreen = true;
      }
      this._commonService.customActivityListSelectOptions.cssClass = '';
      this._commonService.customActivityListUrgencySelectOptions.cssClass = '';
      this._commonService.showSideMenu = false;
    }
  }


  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    this._commonService.showIonApp = false;
    if (!this._commonService.isLoggedIn) {
      localStorage.removeItem('currentImage');
      localStorage.removeItem('helpDetail');
      localStorage.removeItem('currentHeaderImage');
    }
  }

  findParent(el, tag) {
    while (el.parentNode) {
      el = el.parentNode;
      if (el.tagName == tag) {
        return el;
      }
    }
    return null;
  }


  @HostListener('click', ['$event'])
  onClick(event: any) {
    let parElement = '';
    let parentRecursive = false;
    if (event.target instanceof HTMLAnchorElement === false) {
      if (event.target instanceof HTMLElement) {
        parElement = this.findParent(event.target, 'A');
        if (parElement == null || parElement == '' || parElement == undefined) {
          return;
        }
        parentRecursive = true;
      } else {
        return;
      }

    }
    const k = (event.target).parentNode;
    const target = parentRecursive == false ? event.target : parElement;
    if (target.pathname) {
      if ((target.pathname.includes('/frequentlyaskedquestions/') && target.pathname.includes('/faqId/')) || (target.pathname.includes('/documentgenerator/wizard/')) || (target.pathname.includes('/knowledgeitems/')) || (target.pathname.includes('/documentlibrary/standarddocument/'))) {
        if (k) {
          const g = (k.parentNode).id;
          if (g == 'knowledgeBaseLinkContainer') {
            event.preventDefault();
            if (target.pathname) {
              if (target.pathname.includes('/knowledgeitems/')) {
                const k = { KnowledgeBaseItemId: target.pathname.split('/').pop() };
                this.showDetail(k);
              } else {
                this.closeSideMenu('directClosed');
                this.router.navigate([target.pathname]);
              }

            }
          } else {
            if (event.composedPath().length > 0) {
              event.composedPath().forEach(x => {
                if (x.id == 'knowledgeBaseLinkContainer') {
                  event.preventDefault();
                  if (target.pathname) {
                    if (target.pathname.includes('/knowledgeitems/')) {
                      const k = { KnowledgeBaseItemId: target.pathname.split('/').pop() };
                      this.showDetail(k);
                    } else {
                      this.closeSideMenu('directClosed');
                      this.router.navigate([target.pathname]);
                    }

                  }
                }
              });
            }
          }
        }
      }
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(event.target.className === 'owl-dt-control-content owl-dt-control-button-content' || event.type === 'click'){
      return;
    }
    if (!event.target.className.includes('hamburger-menu') && event.target.name != 'menu') {
      this._commonService.showOnlyIcon = true;
    }

    if (event.target.className == 'mat-select-value' || event.target.className.includes('mat-select-placeholder') || event.target.className == 'mat-select-value-text' || (event.target.parentElement && event.target.parentElement.className.includes('mat-select-value-text'))) {
      if (document.querySelector('.cdk-overlay-pane')) {
        if ((<HTMLElement>document.querySelector('.cdk-overlay-pane')).style.top != '') {
          const height = (<HTMLElement>document.querySelector('.cdk-overlay-pane')).offsetHeight;
          const top = (<HTMLElement>document.querySelector('.cdk-overlay-pane')).offsetTop;
          if ((height + top + 47) > window.innerHeight) {
            (<HTMLElement>document.querySelector('.cdk-overlay-pane')).style.top = top - height + 'px';
          } else {
            (<HTMLElement>document.querySelector('.cdk-overlay-pane')).style.top = (<HTMLElement>document.querySelector('.cdk-overlay-pane')).offsetTop + 47 + 'px';
          }
        } else {
          (<HTMLElement>document.querySelector('.cdk-overlay-pane')).style.bottom = parseInt((<HTMLElement>document.querySelector('.cdk-overlay-pane')).style.bottom) + 47 + 'px';
        }

        if (<HTMLElement>document.getElementsByClassName('mat-selected')[0]) {
          if (document.getElementsByClassName('mat-select-panel')[0].scrollTop > (<HTMLElement>document.getElementsByClassName('mat-selected')[0]).offsetTop) {
            document.getElementsByClassName('mat-select-panel')[0].scrollTop = (<HTMLElement>document.getElementsByClassName('mat-selected')[0]).offsetTop - 80;
          }
        }
      }

    }
  }

  @ViewChild(IonSelect, { static: false }) select: IonSelect;

  detailView = false;
  page = '1';
  pageSize = '3';
  categoryPage = '1';
  categoryPageSize = '2';
  knowledgeBaseList: Array<any> = [];
  searchText = '';
  knowledgeBase: any;
  showLoading = false;
  showInnerLoading = false;
  shownAllRecords = false;
  locale: number;

  constructor(
    private menuCtrl: MenuController,
    public _commonService: CommonService,
    private _authService: AuthService,
    private _translationService: TranslationService,
    private _apiService: ApiService,
    private httpClient: HttpClient,
    private _mainService: MainService,
    private router: Router,
    private eRef: ElementRef,
    public ngxSmartModalService: NgxSmartModalService,
    private sanitizer: DomSanitizer,
    // private matomoInjector: MatomoInjector,
    private matomoTracker: MatomoTracker
  ) {
    this.isFromRegisterLink = false;
    this._commonService.windowWidth = window.innerWidth;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        const temp = event.url.substring(0, event.url.lastIndexOf('/') + 1);
        if (temp == '/verifyemail/' || temp == '/Users/Invitation/') {
          this.isFromRegisterLink = true;
        }
      }

      if (event instanceof NavigationEnd) {

        const temp = event.url.substring(0, event.url.lastIndexOf('/') + 1);
        if (temp == '/verifyemail/' || temp == '/Users/Invitation/') {
          this.isFromRegisterLink = true;
        }



        const userName = localStorage.userName ? localStorage.userName : 'Undefined';
        //this.matomoTracker.setUserId(userName);
        this.matomoTracker.setCustomUrl(event.url);
        this.matomoTracker.setReferrerUrl('');
        this.matomoTracker.trackPageView(event.url);


      }

      if (event instanceof NavigationError) {

      }
    });
    this.init();
    this._commonService.closeRightDrawer.asObservable().subscribe((value: any) => {
      this.closeSideMenu(value);
    });

    this._commonService.toggleRightNav.asObservable().subscribe((value: any) => {
      this.closeSideMenu(value);
    });
  }

  async init() {

    this.firstName = '';
    this.roleId = '';
    this.email = '';
    this.expandRightNavBar = false;
    this._commonService.helpDetail = localStorage.helpDetail && localStorage.helpDetail != 'undefined' ? JSON.parse(localStorage.helpDetail) : this.defaultHelpDetails;
    this._commonService.helpImage = localStorage.currentImage && localStorage.currentImage != 'undefined' ? JSON.parse(localStorage.currentImage) : null;
    this._commonService.headerImage = localStorage.currentHeaderImage && localStorage.currentHeaderImage != 'undefined' ? JSON.parse(localStorage.currentHeaderImage) : null;
    this._commonService.phoneNumber = localStorage.DomainSubscriptionContactNumber ? localStorage.DomainSubscriptionContactNumber : '';
    this._commonService.showOnlyIcon = true;






    const data: any = await this.httpClient.get('data/config.json').toPromise();
    this._mainService.url = data.url;
    this._commonService.production = data.production;

    //this.matomoURL = data.urlAnalytics;
    //this.matomoAppId = data.analyticsAppId;
    // this.matomoInjector.init(data.urlAnalytics, parseInt(data.analyticsAppId));
    this.matomoTracker.setSiteId(parseInt(data.analyticsAppId, 10));
    this.matomoTracker.setTrackerUrl(data.urlAnalytics);

    try {
      // check for authkey validation
      this._commonService.showIonApp = false;
      if (this._commonService.isLoggedIn) {
        if (location.href.indexOf('/login') != -1) {
          localStorage.removeItem('authenticationKey');
          this._commonService.isLoggedIn = false;
          this.router.navigate(['/login'], { replaceUrl: true });
        } else {
          if (!this.isFromRegisterLink) {
            await this._commonService.verifyAuthKey();
          }
        }
        this._commonService.showIonApp = true;
        if (localStorage.notificationsUnreadCount && localStorage.notificationsUnreadCount != 'undefined' && localStorage.notificationsUnreadCount != 'null') {
          this._commonService.notificationsUnreadCount = Number(localStorage.notificationsUnreadCount);
        }
        if (!this._commonService.notificationCountSubscription && this._commonService.isLoggedIn && !this.isFromRegisterLink) {
          this._commonService.getNotificationCount();
        }
      } else {
        this._commonService.showIonApp = true;
      }


    } catch (error) {
      setTimeout(() => {
        this._commonService.showIonApp = true;

      }, 3000);
    }

    if (localStorage.locale) {
      this._commonService.locale = Number(localStorage.locale);
      this.locale = Number(localStorage.locale);
    } else {
      this._commonService.locale = 1043;
      localStorage.locale = this._commonService.locale;
    }
    this._translationService.getTranslation(this.locale);
  }

  ngAfterViewInit(): void {
    this._commonService.localeSelect = this.select;
    this.testing = true;

  }

  onBlurMethod(controlName) {
    if (controlName == 'Email') {
      this.controlStatus.Email = true;
    }

  }

  onFocusMethod(controlName) {
    if (controlName == 'Email') {
      this.controlStatus.Email = false;
    }

  }

  ngOnInit(): void {
    const userName = localStorage.userName ? localStorage.userName : 'Undefined';
    this.matomoTracker.setUserId(userName);
    this.matomoTracker.setDocumentTitle('RechtDirect');

    if (window.innerWidth > 800) {
      this._commonService.showSideMenu = true;
      this._commonService.customActivityListSelectOptions.cssClass = 'custom-select-interface';
    } else {
      this._commonService.customActivityListSelectOptions.cssClass = '';
      this._commonService.showSideMenu = false;
    }

    this._commonService.searchText = '';
    if (localStorage.locale && !Number.isNaN(Number(localStorage.locale))) {
      this._commonService.locale = Number(localStorage.locale);
    } else {
      this._commonService.locale = 1043;
      localStorage.locale = this._commonService.locale;
    }


    if (localStorage.Logo) {
      this._commonService.logo = localStorage.Logo;
    } else {
      this._commonService.logo = null;
      localStorage.removeItem('Logo');
    }

    if (localStorage.customerLogo) {
      this._commonService.customerLogo = localStorage.customerLogo;
    } else {
      this._commonService.customerLogo = null;
      localStorage.removeItem('customerLogo');
    }

    this.ktStack = localStorage.ktStackRecords ? JSON.parse(localStorage.ktStackRecords) : [];

    if (localStorage.roleCode == 'UserRole_Administrator') {
      this._commonService.isAdmin = true;
      this._commonService.showDashboardTiles = false;
    }
    if (localStorage.roleCode == 'UserRole_CustomerAdmin') {
      this._commonService.isCustomerAdmin = true;
    }
    if (localStorage.roleCode == 'UserRole_CustomerUser') {
      this._commonService.isCustomerUser = true;
    }
    if (localStorage.roleCode == 'UserRole_Support') {
      this._commonService.isSupportUser = true;
    }
    if (localStorage.roleCode == 'UserRole_Legal') {
      this._commonService.isJuristUser = true;
    }

    if (localStorage.linkedToMultipleDomains) {

      this._commonService.linkedToMultipleDomains = JSON.parse(localStorage.linkedToMultipleDomains);
    }
  }


  toggle(p: any) {

    this._commonService.menuItems.forEach(element => {
      if (element.Menuitemid != p.Menuitemid)
        {element.open = false;}
    });
    p.open = !p.open;

  }

  logout() {
    this._authService.logout();
  }

  openUserInviteModal() {
    this.submitted = false;
    this.ngxSmartModalService.getModal('userInviteModal').open();
    this.form.reset();

  }

  async userInvite() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this._commonService.presentLoading();
    try {
      const data = {
        FirstName: this.firstName,
        EmailAddress: this.email,
        RoleId: this.roleId,
        DomainId: this.domainId
      };
      const res = await this._apiService.inviteUser(data);
      this._commonService.showToast(this._commonService.getLocalTranslation('label-invitationSent'));
      this._commonService.dismiss();
      this.closeUserInviteModal();

    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
      this._commonService.dismiss();
      this.closeUserInviteModal();
    }
  }

  closeUserInviteModal() {
    this.form.reset();
    this.submitted = false;
    this.ngxSmartModalService.getModal('userInviteModal').close();
  }


  toggleMenuItem(menuitem) {
    menuitem.open = !menuitem.open;
  }

  async changeLocale(locale: any) {
    try {
      this._commonService.presentLoading();
      this._commonService.locale = locale;
      localStorage.locale = locale;
      localStorage.removeItem('userRoles');
      localStorage.removeItem('companies');

      // get translations
      await this._translationService.getTranslation(Number(localStorage.locale));
      // get menu items
      const data = await this._apiService.getMenuItems('');
      localStorage.setItem('menuItems', JSON.stringify(data.body));
      this._commonService.menuItems = data.body;
      this._commonService.dismiss();
      // refresh the whole page.
      window.location.reload();
    } catch (err) {
      this._commonService.log(err, this._commonService.ERROR);
    }
  }

  toggleMenu() {
    if (window.innerWidth > 800) {
      //this._commonService.showSideMenu = !this._commonService.showSideMenu;
      this._commonService.showIconOnly = !this._commonService.showIconOnly;
      this._commonService.onToggle.emit('toggled');
      if (this._commonService.showSideMenu) {this._commonService.customActivityListSelectOptions.cssClass = 'custom-select-interface';}
      else {this._commonService.customActivityListSelectOptions.cssClass = '';}
    } else {
      this.menuCtrl.toggle();
    }
  }


  downloadPDF() {
    const downloadLink = document.createElement('a');
    downloadLink.href = '/assets/sample.pdf';
    downloadLink.download = 'DGD 2.0 summary.pdf';
    downloadLink.click();
  }

  onMenuOpen() {
    const ionMenu = document.querySelectorAll('ion-menu');
    ionMenu.forEach((button) => {
      button.shadowRoot.querySelector('.menu-backdrop').setAttribute('style', 'opacity:0 !important;display:none !important;');
    });
  }

  menuOpened() {
    const ionMenu = document.querySelectorAll('ion-menu');
    ionMenu.forEach((button) => {
      button.shadowRoot.querySelector('.menu-backdrop').setAttribute('style', 'opacity:0 !important;display:none !important;');
    });
  }

  changeIcon(iconName) { //REMOVE_ME
    // if(iconName == 'icon_faq'){
    //   this._commonService.menuItems.forEach(element => {
    //     if (element.Icon == iconName)
    //       element.Icon = 'icon_faq_black';
    //     });
    // }
  }

  openSideMenu() {
    this._commonService.closeRightDrawer.next('opened');
  }

  onDismissModal(event) {
    if (this._commonService.openFromPopover) {
      this.closeUserInviteModal();
    }

  }

  onOpenModal() {

    if (this._commonService.openFromPopover) {
      this.form.reset();
      this.submitted = false;
    }

    this.domainId = '';
    //Getting domainId which is set when modal is opened.
    this.domainId = (this.ngxSmartModalService.getModal('userInviteModal').getData() as { domainId?: string })?.domainId;
    this.ngxSmartModalService.resetModalData('userInviteModal');
    if (!this.domainId) {
      this.domainId = localStorage.domainId;
    }
    //If user role is CustomerAdmin then disable role selection and put CustomerUser as default role they can select.
    this.allRoles = JSON.parse(localStorage.userRoles);
    this.allRoles = [...this.allRoles.filter(role => role.UserRoleCode == 'UserRole_CustomerUser' || role.UserRoleCode == 'UserRole_CustomerAdmin')];
    if (localStorage.roleCode == 'UserRole_CustomerAdmin') {
      this.roleId = this.allRoles.find(f => f.UserRoleCode == 'UserRole_CustomerUser').UserRoleId;
      this.disableRoleSelection = true;

    }
    if (localStorage.roleCode == 'UserRole_Administrator' || localStorage.roleCode == 'UserRole_Support') {
      this.disableRoleSelection = false;
    }
  }

  async closeSideMenu(event) {
    this.ktStack = localStorage.ktStackRecords ? JSON.parse(localStorage.ktStackRecords) : [];
    if (event == 'directClosed') {
      if (this.ktStack.length == 0) {
        this.knowledgeBaseList = [];
      }
      // this.knowledgeBaseList = [];
      if (this.rightNav) {
        this.rightNav.close();
      }

      this.detailView = false;
    } else {
      if (localStorage.authenticationKey && localStorage.authenticationKey != 'undefined' && localStorage.authenticationKey != '') {
        if (event == 'closed') {
          if (this.detailView) {
            this.detailView = false;
          } else {
            if (this.rightNav) {
              this.rightNav.toggle();
            }

            if (this.ktStack.length == 0) {
              this.knowledgeBaseList = [];
            }
          }
        }

        if (event == 'opened') {
          // this.ktStack = localStorage.ktStackRecords?JSON.parse(localStorage.ktStackRecords):[];
          if (this.ktStack.length == 0) {
            localStorage.ktShownAllRecords = false;
            this.shownAllRecords = false;
            this.showLoading = true;
            this.searchText = '';
            localStorage.scrollPosition = 0;
            this.getKnowledgeBaseList('', this.searchText, '1', '3', '1', '2');
          } else {
            const w = { KnowledgeBaseItemId: this.ktStack[this.ktStack.length - 1] };
            this.showDetail(w);
          }
          if (this.rightNav) {
            this.rightNav.toggle();
          }

        }
      }
    }
  }

  rightNavHome() {
    if (localStorage.ktShownAllRecords == 'true') {
      this.shownAllRecords = true;
    } else {
      this.shownAllRecords = false;
    }
    this.showLoading = true;
    this.searchText = localStorage.ktSearchText ? localStorage.ktSearchText : '';
    this.ktStack = [];
    localStorage.ktStackRecords = JSON.stringify(this.ktStack);
    this.knowledgeBaseList = localStorage.ktKnowledgeBaseList ? JSON.parse(localStorage.ktKnowledgeBaseList) : [];
    if (this.knowledgeBaseList.length == 0) {
      this.getKnowledgeBaseList('', this.searchText, '1', '3', '1', '2');
    }
    // this.getKnowledgeBaseList('', this.searchText, '1', '3', '1', '2');
    this.detailView = !this.detailView;
    setTimeout(() => {
      if (this.contEl) {
        this.checkPosition = localStorage.scrollPosition ? localStorage.scrollPosition : 0;
        this.contEl.nativeElement.scrollTop = this.checkPosition;
      }
    }, 100);
    this.showLoading = false;
  }

  rightNavBack() {
    this.ktStack = localStorage.ktStackRecords ? JSON.parse(localStorage.ktStackRecords) : [];
    if (this.ktStack.length == 0) {
      this.detailView = !this.detailView;
    } else {
      this.ktStack.pop();
      localStorage.ktStackRecords = JSON.stringify(this.ktStack);
      if (this.ktStack.length == 0) {
        this.rightNavHome();
      } else {
        const w = { KnowledgeBaseItemId: this.ktStack[this.ktStack.length - 1], GotBack: true };
        this.showDetail(w);
      }
    }
  }

  drawerClicked(event) {
    console.log('The event', event);
  }

  async showDetail(item) {
    //this._commonService.presentLoading();
    this.showLoading = true;
    try {
      this.knowledgeBase = {};
      this.detailView = true;
      const res = await this._apiService.getKnowledgeBaseDetails(item.KnowledgeBaseItemId, 1);
      if (!item.GotBack) {
        if (this.ktStack[this.ktStack.length - 1] != item.KnowledgeBaseItemId)
          {this.ktStack.push(item.KnowledgeBaseItemId);}
      }
      localStorage.ktStackRecords = JSON.stringify(this.ktStack);
      this.knowledgeBase = res.body;
      const locale = Number((localStorage.locale && localStorage.locale != '') ? localStorage.locale : 1043);
      if (this.knowledgeBase && this.knowledgeBase.Translations) {
        const temp = this.knowledgeBase.Translations.find(x => x.LocaleId == locale);
        this.knowledgeBase.Title = temp.Title;
        // if(this.knowledgeBase && this.knowledgeBase.Title && this.knowledgeBase.Title.length > 37 ) {
        //   this.knowledgeBase.Title = this.knowledgeBase.Title.substring(0,37) + "...";
        // }
        this.knowledgeBase.Description = this.sanitizer.bypassSecurityTrustHtml(temp.Description);
      }

      //this._commonService.dismiss();
      this.showLoading = false;
    } catch (error) {
      this.showLoading = false;
      //this._commonService.dismiss();
    }
  }

  async getKnowledgeBaseList(id, searchText, page, pagesize, categoryPage, categoryPageSize) {
    try {
      //this._commonService.presentLoading();
      //this.showLoading = true;
      const res = await this._apiService.getKnowledgebasePerCategory(id, searchText, page, pagesize, categoryPage, categoryPageSize);
      this.knowledgeBaseList = res.body;
      localStorage.ktKnowledgeBaseList = JSON.stringify(this.knowledgeBaseList);
      /*if( this.knowledgeBaseList.length > 0){
        this.knowledgeBaseList.forEach(element => {
          if(element.KnowledgeBaseItems.length > 0){
            element.KnowledgeBaseItems = [...element.KnowledgeBaseItems.sort(function (a, b) {
              let alc = a.Score, blc = b.Score;
              return alc > blc ? -1 : alc < blc ? 1 : 0;
            })];
          }
        });
      }*/



      this.showLoading = false;
      this.showInnerLoading = false;
      //this._commonService.dismiss();
    } catch (error) {
      //this._commonService.dismiss();
      this.showLoading = false;
      this.showInnerLoading = false;
    }

  }

  viewAll(categoryPageSize) {
    this.showInnerLoading = true;
    this.categoryPageSize = categoryPageSize;
    localStorage.ktSearchText = this.searchText ? this.searchText : '';
    this.getKnowledgeBaseList('', this.searchText, '1', '3', '1', categoryPageSize);
    if (categoryPageSize == 0) {
      localStorage.ktShownAllRecords = true;
      this.shownAllRecords = true;
    } else {
      localStorage.ktShownAllRecords = false;
      this.shownAllRecords = false;
    }
  }

  scrollRecord(event) {
    if (!this.detailView) {
      this.checkPosition = event.currentTarget.scrollTop;
      localStorage.scrollPosition = this.checkPosition;
    }
  }

  async showMore(item) {
    //this.getKnowledgeBaseList(item.CategoryId,this.searchText,'1', item.KnowledgeBaseItems.length + 3,'1',this.categoryPageSize);
    try {
      //this._commonService.presentLoading();
      this.showInnerLoading = true;
      this.searchText = localStorage.ktSearchText ? localStorage.ktSearchText : this.searchText;
      const res = await this._apiService.getKnowledgebasePerCategory(item.CategoryId, this.searchText, '1', item.KnowledgeBaseItems.length + 3, '1', this.categoryPageSize);
      item.KnowledgeBaseItems = res.body[0].KnowledgeBaseItems;
      localStorage.ktKnowledgeBaseList = JSON.stringify(this.knowledgeBaseList);
      //this.knowledgeBaseList = res['body'];
      //this._commonService.dismiss();
      this.showInnerLoading = false;
    } catch (error) {
      this.showInnerLoading = false;
      //this._commonService.dismiss();
    }
  }

  expandRightNav() {
    if (this.expandRightNavBar) {
      //collapsed
      //loadCountly("RightNavBarCollapsed");
      this.expandRightNavBar = false;
    } else {
      //expanded
      //loadCountly("RightNavBarExpanded");
      this.expandRightNavBar = true;
    }
  }


}
