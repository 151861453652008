import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private _mainService: MainService) { }

  // Verify AuthKey
  verifyAuthkey(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Login/VerifyAuthKey?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }


  // Translations
  getTranslations(searchText, orderBy, orderDir, page, pageSize) {
    return this._mainService.callWebserviceHttpGet("SystemTranslations?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  // Domains/Logos
  getDomainLogo(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Domains/Logos?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

   // Dashboard
   getDashboard(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Dashboard?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }


  //Verify OtpCode
  verifyOtpCode(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("VerifyOtp?OnlyOtp=true&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //Verify invite user otpCOde
  verifyInviteUserOtpCode(data): Promise<any>{
    return this._mainService.callWebserviceHttpPost("Users/Invitation/OtpCode?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }



  // Objects Maintenance
  getMaintenanceObjects(searchText, orderBy, orderDir, objectGroupId, objectTypeId, companyId, page, pageSize, status): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Objects/Maintenance?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&objectGroupId=" + objectGroupId + "&objectTypeId=" + objectTypeId + "&companyId=" + companyId + "&show=" + status, localStorage.authenticationKey);
  }

  // Objects Maintenance Filter
  getMaintenanceObjectsFilter(searchText, orderBy, orderDir, objectTypeId, page, pageSize, status, data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Objects/Filter?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&objectTypeId=" + objectTypeId + "&show=" + status, data, localStorage.authenticationKey);
  }

  // Objects
  getObjects(searchText, orderBy, orderDir, objectGroupId, companyId, objectTypeId, page, pageSize, status): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Objects?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&objectGroupId=" + objectGroupId + "&companyId=" + companyId + "&objectTypeId=" + objectTypeId + "&show=" + status, localStorage.authenticationKey);
  }

  exportObjects(orderBy, orderDir, page, pageSize, status): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Objects/Report?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&status=" + status, localStorage.authenticationKey);
  }

  getObject(objectId, isMaintenance, companyIds): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Objects/" + objectId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&isMaintenance=" + isMaintenance + "&companyIds=" + companyIds, localStorage.authenticationKey);
  }

  putObject(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Objects?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postObject(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Objects?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteObjects(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Objects/", ids, localStorage.authenticationKey);
  }

  // ObjectGroups
  getObjectGroups(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ObjectGroups?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  // ObjectGroups
  getObjectGroupsFilter(searchText, orderBy, orderDir, page, pageSize, data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ObjectGroups/Filter?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, data, localStorage.authenticationKey);
  }

  exportObjectGroups(orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ObjectGroups/Report?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getObjectGroupsList(objectGroupId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ObjectGroups/List?objectGroupId=" + (objectGroupId ? objectGroupId : '') + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getObjectGroup(objectGroupId, searchText, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ObjectGroups/" + objectGroupId + "?search=" + searchText + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  putObjectGroup(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("ObjectGroups?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postObjectGroup(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ObjectGroups?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteObjectGroup(data): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("ObjectGroups/", data, localStorage.authenticationKey);
  }

  // ObjectFolders
  getObjectFolders(objectFolderId, isMaintenance): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Objects/Folders/" + objectFolderId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&isMaintenance=" + isMaintenance, localStorage.authenticationKey);
  }

  patchObjectFolders(objectFolderId, data): Promise<any> {
    return this._mainService.callWebserviceHttpPatch("Objects/Folders/" + objectFolderId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  getObjectsFolders(objectId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Objects/" + objectId + "/Folders?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putObjectFolder(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Objects/Folders?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postObjectFolder(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Objects/Folder?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteObjectFolder(data): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Objects/Folders/", data, localStorage.authenticationKey);
  }

  // CompanyObjects
  getCompanyObjects(companyId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies/" + companyId + "/Objects?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  // CompanyObjectFolders
  getCompanyObjectFolders(companyId, objectId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies/" + companyId + "/Object/" + objectId + "/Folders?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putCompanyObjectFolders(companyId, objectId, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Companies/" + companyId + "/Object/" + objectId + "/Folders?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // CompanyUserPermissions
  getCompanyUserPermissions(companyId, userId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies/" + companyId + "/CompanyUsers/" + userId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putCompanyUserPermissions(companyId, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Companies/" + companyId + "/CompanyUsers?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // CompanyUserPermissions
  getCompanyObjectGroupsPermissions(companyId, objectGroupId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies/" + companyId + "/ObjectGroupCompanies/" + objectGroupId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putCompanyObjectGroupsPermissions(companyId, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Companies/" + companyId + "/ObjectGroupCompanies?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // Domains


  getDomains(searchText, orderBy, orderDir, page, pageSize, isMaintenance, teamleaderTags= []): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Domains?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&teamleaderTags=" + teamleaderTags + "&isMaintenance=" + isMaintenance, localStorage.authenticationKey);
  }

  getDomainLocales(searchText, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Domains/Locales?search=" + encodeURIComponent(searchText) + "&page=" + page + "&pageSize=" + pageSize + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getDomain(domainId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Domains/" + domainId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putDomain(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Domains?localeCode =" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postDomain(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Domains?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteDomains(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Domains/", ids, localStorage.authenticationKey);
  }

  // Companies
  getCompanies(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getDomainCompanies(searchText, orderBy, orderDir, page, pageSize, domainId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize +"&domainId=" + domainId, localStorage.authenticationKey);
  }

  // Companies/Filter
  getCompaniesFilter(searchText, orderBy, orderDir, page, pageSize, status, data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Companies/Filter?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&status=" + status, data, localStorage.authenticationKey);
  }

  exportCompanies(orderBy, orderDir, page, pageSize, status): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies/Report?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&status=" + status, localStorage.authenticationKey);
  }

  getCompany(companyId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies/" + companyId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getCompanyWizard(companyId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies/Wizard/" + companyId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  // putCompany(data, status): Promise<any> {
  //   return this._mainService.callWebserviceHttpPut("Companies?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  // }

  postCompany(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Companies?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postCompanyWithPassword(data,password, newDomain): Promise<any> {
    return this._mainService.callWebserviceHttpPostWithPassword("Companies?newDomain="+ newDomain + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, password, localStorage.authenticationKey);
  }

  syncTeamLeader(data,companyId,password): Promise<any> {
    return this._mainService.callWebserviceHttpPutWithPassword("Companies/" + companyId +"/TeamLeaderSync?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, password, localStorage.authenticationKey);
  }

  deleteCompanies(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Companies/", ids, localStorage.authenticationKey);
  }

  copyCompany(ids): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Companies/Copy?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), ids, localStorage.authenticationKey);
  }

  // Users

  getUsers(searchText, orderBy, orderDir, page, pageSize, roleCode, status, isReport, isMaintenance, emailunverified, DomainId, newsletterSubscription): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Users?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&roleCode=" + roleCode + "&status=" + status + "&isReport=" + isReport + "&isMaintenance=" + isMaintenance + "&emailunVerified=" + emailunverified+ "&DomainId=" + DomainId + "&newsletterSubscription=" +  newsletterSubscription, localStorage.authenticationKey);
  }

  exportUsers(roleCode, orderBy, orderDir, page, pageSize, status): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Users/Report?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&roleCode=" + roleCode + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&status=" + status, localStorage.authenticationKey);
  }

  getUser(userId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Users/" + userId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putUser(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Users?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putUserWithPasswordPrompt(data, password): Promise<any> {
    return this._mainService.callWebserviceHttpPutWithPassword("Users?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, password, localStorage.authenticationKey);
  }

  postUserWithPasswordPrompt(data, password): Promise<any> {
    return this._mainService.callWebserviceHttpPostWithPassword("Users?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, password, localStorage.authenticationKey);
  }

  getUserProfile(userId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Users/Profile/" + userId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putUserProfile(data, password): Promise<any> {
    return this._mainService.callWebserviceHttpPutWithPassword("Users/Profile?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, password, localStorage.authenticationKey);
  }

  postUser(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Users?localeCode =" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteUsers(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Users/", ids, localStorage.authenticationKey);
  }

  //deleteUser
  deleteUserWithPasswordPrompt(userId, password) {
    return this._mainService.callWebserviceHttpUserDelete("Users/", userId, password, localStorage.authenticationKey);
  }

  resetRetryCount(userId, data) {
    return this._mainService.callWebserviceHttpPut("Users/" + userId + "/ResetRetryCount?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // UserObjectGroupsPermissions
  getUserObjectGroupsPermissions(userId, objectGroupId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Users/" + userId + "/ObjectGroupUsers/" + objectGroupId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putUserObjectGroupsPermissions(userId, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Users/" + userId + "/ObjectGroupUsers?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // Locales
  getLocales(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Locales?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getLocale(LocaleId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Locales/" + LocaleId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putLocale(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Locales?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postLocale(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Locales?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteLocale(data): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Locales/", data, localStorage.authenticationKey);
  }

  // Dashboard
  getDashboardPieChart(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ActivityOverView/Graph?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  // User Roles
  getUserRoles(searchText, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("UserRoles?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getUserRole(userRoleId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("UserRoles/" + userRoleId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putUserRole(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("UserRoles?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postUserRole(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("UserRoles?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // Settings
  getSettings(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Settings?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }
  getModules(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Modules?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getSetting(settingId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Settings/" + settingId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putSetting(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Settings?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postSetting(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Settings?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteSetting(data): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Settings/", data, localStorage.authenticationKey);
  }

  // Notification Templates
  getNotificationTemplates(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("NotificationTemplates?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getNotificationTemplate(settingId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("NotificationTemplates/" + settingId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putNotificationTemplate(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("NotificationTemplates?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postNotificationTemplate(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("NotificationTemplates?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteNotificationTemplate(data): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("NotificationTemplates/", data, localStorage.authenticationKey);
  }

  // Folders
  getFolders(searchText, orderBy, orderDir, page, pageSize, appendCode): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Folders?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&appendCode=" + appendCode, localStorage.authenticationKey);
  }

  getTemplateFoldersTree(companyId, objectId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Folders/Tree?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&companyId=" + companyId + "&objectId=" + objectId, localStorage.authenticationKey);
  }

  getMergedFoldersTree(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Folders/MergedTree?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  getAllFolders(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ActivityOverView/Folders?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getFolder(folderId, searchText, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Folders/" + folderId + "?search=" + searchText + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  putFolder(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Folders?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postFolder(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Folders?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteFolder(data): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Folders/", data, localStorage.authenticationKey);
  }

  // ObjectTypes/Folder
  getObjectTypesFolder(objectTypeFolderId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ObjectTypes/Folder/" + objectTypeFolderId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putObjectTypesFolder(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("ObjectTypes/Folder?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //Notificatons
  getNotifications(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Notifications?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getNotification(notificationId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Notifications/" + notificationId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putNotification(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Notifications?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postNotification(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Notifications?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteNotifications(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Notifications/", ids, localStorage.authenticationKey);
  }

  //Messages
  getMessages(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Messages?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getMessage(messageId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Messages/" + messageId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putMessage(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Messages?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postMessage(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Messages?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteMessages(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Messages/", ids, localStorage.authenticationKey);
  }

  //System Translations
  getSystemTranslations(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("SystemTranslations?search=" + encodeURIComponent(searchText) + "&page=" + page + "&pageSize=" + pageSize + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getSystemTranslation(systemTranslationCodeId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("SystemTranslations/" + systemTranslationCodeId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putSystemTranslation(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("SystemTranslations?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postSystemTranslation(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("SystemTranslations?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteSystemTranslation(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("SystemTranslations/", ids, localStorage.authenticationKey);
  }

  exportSystemTranslations(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("SystemTranslations/Export?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  importSystemTranslations(data): Promise<any> {
    return this._mainService.callWebserviceHttpPutFile("SystemTranslations/Import?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // ScheduleIntervals
  getScheduleIntervals(searchText, orderBy = 'interval', orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ScheduleIntervals?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + (orderBy == '' ? '' : orderBy) + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getScheduleInterval(scheduleIntervalId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ScheduleIntervals/" + scheduleIntervalId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putScheduleInterval(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("ScheduleIntervals?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postScheduleInterval(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ScheduleIntervals?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteScheduleIntervals(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("ScheduleIntervals/", ids, localStorage.authenticationKey);
  }

  // Objecttypes
  getObjectTypes(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ObjectTypes?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getObjectType(objectTypeId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ObjectTypes/" + objectTypeId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getObjectTypeFolders(objectTypeId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ObjectTypes/" + objectTypeId + "/Folders?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putObjectType(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("ObjectTypes?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postObjectType(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ObjectTypes?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteObjectTypes(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("ObjectTypes/", ids, localStorage.authenticationKey);
  }

  // MenuItems
  getMenuItems(searchText): Promise<any> {
    return this._mainService.callWebserviceHttpGet("MenuItems?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  // Permissions
  getPermissions(searchText, roleId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Permissions?roleId=" + roleId + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postPermissions(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Permissions?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // Messages/Domains
  getDomainMessages(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Messages/Domains?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  // getActivityLists
  getActivityLists(searchText, orderBy, orderDir, page, pageSize, data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ActivityOverView?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, data, localStorage.authenticationKey);
  }

  //reports
  exportReports(orderBy, orderDir, data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ActivityOverView/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&dateTo=", data, localStorage.authenticationKey);
  }

  exportObjectFiles(data): Promise<any> {
    return this._mainService.callWebserviceHttpGetFileWithPost("Objects/Export?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postObjectFiles(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ObjectFiles?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  getObjectFiles(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ObjectFiles?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  putObjectFiles(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("ObjectFiles?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  downloadObjectFile(objectFileId): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("ObjectFiles/" + objectFileId + "/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }


  exportChecklistReport(data): Promise<any> {
    return this._mainService.callWebserviceHttpGetFileWithPost("Reports/Checklist?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  exportTotalReport(data): Promise<any> {
    return this._mainService.callWebserviceHttpGetFileWithPost("Reports/TotalReport?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  getObjectDataUsage(orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Reports/DataUsage?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  exportDataUsage(orderBy, orderDir): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("Reports/DataUsage/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir, localStorage.authenticationKey);
  }

  exportUserAction(data): Promise<any> {
    return this._mainService.callWebserviceHttpGetFileWithPost("Reports/UserActions?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // Approve Uploads
  getApproveUploads(dateTo, dateFrom, urgency, objectFolderId, companyId, objectGroupId, objectId, userId, searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("FolderEntries?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&dateTo=" + dateTo + "&dateFrom=" + dateFrom + "&urgency=" + urgency + "&folderId=" + objectFolderId + "&companyId=" + companyId + "&objectGroupId=" + objectGroupId + "&objectId=" + objectId + "&userId=" + userId, localStorage.authenticationKey);
  }

  // File
  getFiles(searchText, folderEntryId, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("FolderEntries/" + folderEntryId + "/Files?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getFile(folderEntryId, fileId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("FolderEntries/" + folderEntryId + "/Files/" + fileId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putFile(folderEntryId, fileId, data, fileSize): Promise<any> {
    return this._mainService.callWebserviceHttpPutFile("FolderEntries/" + folderEntryId + "/Files/" + fileId + "?fileSize=" + fileSize + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postFile(folderEntryId, data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("FolderEntries/" + folderEntryId + "/Files?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteFile(folderEntryId, ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("FolderEntries/" + folderEntryId + "/Files/", ids, localStorage.authenticationKey);
  }

  downloadFile(folderEntryId, fileId): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("FolderEntries/" + folderEntryId + "/Files/" + fileId + "/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  viewFile(folderEntryId, fileId): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("FolderEntries/" + folderEntryId + "/Files/" + fileId + "/View?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  // FolderEntries
  getFolderEntries(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("FolderEntries?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&isMaintenance=1", localStorage.authenticationKey);
  }

  getFolderEntry(activityListId, folderEntryId): Promise<any> {
    if (folderEntryId) {
      return this._mainService.callWebserviceHttpGet("FolderEntries/" + folderEntryId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
    } else {
      return this._mainService.callWebserviceHttpGet("FolderEntries/0?activityListId=" + activityListId + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
    }
  }

  putFolderEntry(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("FolderEntries?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  postFolderEntry(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("FolderEntries?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  moveFolderEntry(objectFolderId, folderEntryId, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Objects/Folder/" + objectFolderId + "/FolderEntries/" + folderEntryId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteFolderEntry(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("FolderEntries/", ids, localStorage.authenticationKey);
  }

  //FolderEntryStatuses
  getFolderEntryStatuses() {
    return this._mainService.callWebserviceHttpGet("FolderEntryStatuses?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  // PasswordChangeRequest
  passwordChangeRequest(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("PasswordChangeRequest?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, "");
  }

  verifyPasswordChangeRequest(id): Promise<any> {
    return this._mainService.callWebserviceHttpGet("PasswordChangeRequest/" + id + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), "");
  }

  changePassword(data, id): Promise<any> {
    return this._mainService.callWebserviceHttpPost("PasswordChangeRequest/" + id + "/Reset?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, "");
  }

  // Notification Configuration
  getDefaultNotificationConfigurations(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("NotificationConfigurations?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postNotificationConfigurations(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("NotificationConfigurations?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  getCompanyNotificationConfiguration(companyId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("NotificationConfigurations/" + companyId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  deleteNotificationConfiguration(notificationConfigurationId) {
    return this._mainService.callWebserviceHttpDelete("NotificationConfigurations/", notificationConfigurationId, localStorage.authenticationKey);
  }

  // Sync Domains
  postSyncDomains(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Domains/Sync?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //Notification
  postNotifications(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Notifications?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // Notification Count
  getNotificationsUnreadCount() {
    return this._mainService.callWebserviceHttpGet("Notifications/UnreadCount?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  //Monitoring screen data
  getMonitoringScreenData() {
    return this._mainService.callWebserviceHttpGet("MonitoringScreen?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postMonitoringScreen(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("MonitoringScreen?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //user ratings
  postUserRatings(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("UserRatings?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  //document template
  getDocumentTemplates(searchText, orderBy, orderDir, page, pageSize, wizard, documentTemplateTypeId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&wizard=" + wizard + "&documentTemplateTypeId=" + documentTemplateTypeId, localStorage.authenticationKey);
  }
  getDocumentTemplatesList(searchText, orderBy, orderDir, page, pageSize, wizard, documentTemplateTypeId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates?isList=1&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&wizard=" + wizard + "&documentTemplateTypeId=" + documentTemplateTypeId, localStorage.authenticationKey);
  }
  getNonFavouriteDocumentTemplatesList(searchText, orderBy, orderDir, page, pageSize, wizard, documentTemplateTypeId,excludeFavorite): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates?isList=1&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&wizard=" + wizard + "&documentTemplateTypeId=" + documentTemplateTypeId+"&excludeFavorite="+excludeFavorite, localStorage.authenticationKey);
  }
  getFavouriteDocumentTemplatesList(searchText, orderBy, orderDir, page, pageSize, wizard, documentTemplateTypeId,isFavorite): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates?isList=1&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&wizard=" + wizard + "&documentTemplateTypeId=" + documentTemplateTypeId +"&isFavorite=" + isFavorite, localStorage.authenticationKey);
  }
  getTemplateDetails(templateId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates/" + templateId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getDocumentTemplateInfoDetails(templateId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates/" + templateId + "/InformationBlock", localStorage.authenticationKey);
  }

  getTemplateType(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplate-Types?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getTemplateDataType(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentBookmarkTypes?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getDocumentQuestionFieldDataTypes(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentQuestionFieldDataTypes?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getDocumentQuestionSupport(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentQuestion-Support?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getDocumentQuestionFieldSection(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentQuestion-Sections?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  deleteDocumentQuestionSections(id) {
    return this._mainService.callWebserviceHttpDelete("DocumentQuestion-Sections/", id, localStorage.authenticationKey);
  }
  postDocumentQuestionSections(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("DocumentQuestion-Sections?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  postDocumentQuestionFields(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("DocumentQuestion-Fields?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  deleteDocumentQuestionsFields(id) {
    return this._mainService.callWebserviceHttpDelete("DocumentQuestion-Fields/", id, localStorage.authenticationKey);
  }

  getDownloadDocument(id): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("DocumentTemplate-Versions/" + id + "/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  postDocumentQuestion(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("DocumentQuestions?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  postDocumentQuestionFieldValue(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("DocumentQuestionFieldValues?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  deleteDocumentQuestionFieldValue(id) {
    return this._mainService.callWebserviceHttpDelete("DocumentQuestionFieldValues/", id, localStorage.authenticationKey);
  }
  putDocumentQuestion(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("DocumentQuestions?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  deleteDocumentQuestions(id) {
    return this._mainService.callWebserviceHttpDelete("DocumentQuestions/", id, localStorage.authenticationKey);
  }
  deleteDocumentTemplates(templateid) {
    return this._mainService.callWebserviceHttpDelete("DocumentTemplates/", templateid, localStorage.authenticationKey);
  }

  getTemplateField(templateId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates-Fields/" + templateId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getVersions(templateId, searchText, orderBy, orderDir, showArchive): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplate-Versions?documentTemplateId=" + templateId + "&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&isArchive=" + showArchive, localStorage.authenticationKey)
  }

  getVersionDetails(versionId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplate-Versions/" + versionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getVersionTreeDetails(versionId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplate-Versions/" + versionId + "/TreeView?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getActiveVersion(versionId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates/" + versionId + "/ActiveVersion?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }


  postTemplateDetails(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("DocumentTemplates?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putTemplateDetails(id, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("DocumentTemplates/" + id + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putDocumentTemplateDetails(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("DocumentQuestionTemplateFields", data, localStorage.authenticationKey);
  }

  postVersionDetails(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("DocumentTemplate-Versions?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  getDocumentQuestionSection(id) {
    return this._mainService.callWebserviceHttpGet("DocumentQuestion-Sections/" + id + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getDocumentQuestions(id) {
    return this._mainService.callWebserviceHttpGet("DocumentQuestions?documentTemplateVersionId=" + id + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getDocumentQuestion(id) {
    return this._mainService.callWebserviceHttpGet("DocumentQuestions/" + id + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getDocumentQuestionFields(id) {
    return this._mainService.callWebserviceHttpGet("DocumentQuestion-Fields?documentQuestionId=" + id + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getDocumentQuestionFieldValues(id) {
    return this._mainService.callWebserviceHttpGet("DocumentQuestionFieldValues?documentQuestionFieldId=" + id + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getDocumentQuestionField(id) {
    return this._mainService.callWebserviceHttpGet("DocumentQuestion-Fields/" + id + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getDocumentTemplateFieldList(id) {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates-Fields/" + id + "/List", localStorage.authenticationKey);
  }

  getDocumentQuestionDataTypeMapping() {
    return this._mainService.callWebserviceHttpGet("DocumentQuestionDataTypeMappings?documentQuestionFieldDataTypeId=&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getDocumentQuestionFieldValue(id) {
    return this._mainService.callWebserviceHttpGet("DocumentQuestionFieldValues/" + id + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  postTemplateVersion(data): Promise<any> {
    return this._mainService.callWebserviceHttpFormPost("DocumentTemplate-Versions?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }


  //Information block
  getInformationBlock(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("InformationBlocks?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getInformationBlockDetails(informationid): Promise<any> {
    return this._mainService.callWebserviceHttpGet("InformationBlocks/" + informationid + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getModuleDetails(data): Promise<any> {
    return this._mainService.callWebserviceHttpGet("InformationBlocks?moduleName=" + data + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  deleteInformationBlock(informationid) {
    return this._mainService.callWebserviceHttpDelete("InformationBlocks/", informationid, localStorage.authenticationKey);
  }

  postInformationBlock(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("InformationBlocks?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
 
  //subscriptonSetting
  getSubsciptionSetting(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("SubscriptionSettings?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getSubsciptionSettingDetail(id,domainId,searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("SubscriptionSettings?domainSubscriptionNameId=" + id + "&domain=" + domainId + "&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  postSubsciptionSetting(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("SubscriptionSettings?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteSubscription(subscriptionId) {
    return this._mainService.callWebserviceHttpDelete("DomainSubscriptions/", subscriptionId, localStorage.authenticationKey);
  }

//Test
  //quickscan tree

  getQuickScanTree(quickscanid) {
    return this._mainService.callWebserviceHttpGet("QuickScans/" + quickscanid + "/tree?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getQuickScanList(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("QuickScans?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getQuickScanDetail(quickscanid) {
    return this._mainService.callWebserviceHttpGet("QuickScans/" + quickscanid + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postQuickScan(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("QuickScans?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putQuickScan(data, quickscanid): Promise<any> {
    return this._mainService.callWebserviceHttpPut("QuickScans/" + quickscanid + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }


  deleteQuickScan(quickscanid) {
    return this._mainService.callWebserviceHttpDelete("QuickScans/", quickscanid, localStorage.authenticationKey);
  }

  //quickscan section

  getQuickScanSectionList(quickscanId) {
    return this._mainService.callWebserviceHttpGet("QuickScans/" + quickscanId + "/QuickScanSection?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getQuickScanSectionDetail(sectionid) {
    return this._mainService.callWebserviceHttpGet("QuickScanSection/" + sectionid + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postQuickScanSection(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("QuickScanSection?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putQuickScanSection(data, quickscansectionId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("QuickScanSection/" + quickscansectionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteQuickScanSection(quickscansectionId) {
    return this._mainService.callWebserviceHttpDelete("QuickScanSection/", quickscansectionId, localStorage.authenticationKey);
  }

  //quickscan question
  getQuickScanQuestionList(quickscanId, searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("QuickScanQuestion/QuickScan/" + quickscanId + "?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }


  getQuickScanQuestionDetail(questionId) {
    return this._mainService.callWebserviceHttpGet("QuickScanQuestion/" + questionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postQuickScanQuestion(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("QuickScanQuestion?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putQuickScanQuestion(data, quickscanquestionId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("QuickScanQuestion/" + quickscanquestionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteQuickScanQuestion(quickscanquestionId) {
    return this._mainService.callWebserviceHttpDelete("QuickScanQuestion/", quickscanquestionId, localStorage.authenticationKey);
  }


  getHelpTextList(searchText, orderBy, orderDir, page, pageSize, moduleName): Promise<any> {
    return this._mainService.callWebserviceHttpGet("HelpText?modulename="+ moduleName +"&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }


  //quickscan answer

  getSuggestionList(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("QuickScanSuggestion?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }


  getQuickScanAnswerDetail(answerId) {
    return this._mainService.callWebserviceHttpGet("QuickScanAnswer/" + answerId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postQuickScanAnswer(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("QuickScanAnswer?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putQuickScanAnswer(data, answerId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("QuickScanAnswer/" + answerId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deleteQuickScanAnswer(answerId) {
    return this._mainService.callWebserviceHttpDelete("QuickScanAnswer/", answerId, localStorage.authenticationKey);
  }

  getAnswerSuggestionList(quickscanId, searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Suggestions/QuickScan/" + quickscanId + "?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getAnswerSectionList(quickscanId, searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("QuickScanSection/QuickScan/" + quickscanId + "?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  //QuickScan Fill

  startQuickScan(data, temp): Promise<any> {
    return this._mainService.callWebserviceHttpPost("QuickScanForm?startnew=" + temp  +"&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  endQuickScan(data, quickScanFormId): Promise<any> {
    return this._mainService.callWebserviceHttpPatch("QuickScanForm/" + quickScanFormId, data, localStorage.authenticationKey);
  }
  
  endQuickScanReport(quickScanFormId, onlyReport): Promise<any> {
    return this._mainService.callWebserviceHttpGet("QuickScanForm/" + quickScanFormId +"/Report?OnlyReport=" + onlyReport, localStorage.authenticationKey);
  }

  downloadQuickScanReport(quickScanFormId): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("QuickScanForm/" + quickScanFormId + "/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }



  //QuickScanSection Change 

  getNextSection(quickScanSectionId, quickScanFormId) {
    return this._mainService.callWebserviceHttpGet("QuickScanSection/" + quickScanSectionId + "/QuickScanForm/" + quickScanFormId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  //post quickscan
  postFillQuickScan(data, quickScanFormId): Promise<any> {
    return this._mainService.callWebserviceHttpPost("QuickScanFormData/" + quickScanFormId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }



  //quickScan tree sort

  sortQuickScanSection(data, quickscanId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("QuickScans/" + quickscanId + "/Sections/Sort?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  sortQuickScanQuestion(data, quickscanSectionId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("QuickScanSection/" + quickscanSectionId + "/Questions/Sort?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  sortQuickScanAnswer(data, quickscanQuestionId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("QuickScansQuestion/" + quickscanQuestionId + "/Answers/Sort?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //Suggestions tree
  getSuggestionTree(quickscanid) {
    return this._mainService.callWebserviceHttpGet("Suggestions/QuickScan/" + quickscanid + "/tree?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }


  postSuggestionTree(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Suggestions?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //quickscan load detail
  getMergedQuickScanDetail() {
    return this._mainService.callWebserviceHttpGet("QuickScans?loadAll=1&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  //Data Types
  getDataTypes(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DataTypes?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&isMaintenance=1", localStorage.authenticationKey);
  }

  //FAQS
  getFaqs(searchText, orderBy, orderDir, page, pageSize, isFaqBlock): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Faqs?isFaqBlock=" + isFaqBlock +"&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  deleteFaqs(faqIds) {
    return this._mainService.callWebserviceHttpDelete("Faqs/", faqIds, localStorage.authenticationKey);
  }

  getFaqDetails(faqId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Faqs/" + faqId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postFaq(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Faqs?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putFaq(data, faqId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Faqs/" + faqId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //invite user
  inviteUser(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Users/Invitation?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);

  }

  veriftUserInviteEmail(tokenId): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Users/Invitation/" + tokenId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), '', localStorage.authenticationKey);

  }

  getInviteUserDomainLocales(tokenId): Promise<any>{
    return this._mainService.callWebserviceHttpGet("Users/Invitation/" + tokenId + "/Locales", localStorage.authenticationKey);
  }

  putInviteUser(data, tokenId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Users/Invitation/" + tokenId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //take tour
  patchTakeTour(data): Promise<any> {
    return this._mainService.callWebserviceHttpPatch("Users/Profile?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //Invoicing

  checkInvoice(){
    return this._mainService.callWebserviceHttpGet("billing/HeartBeat?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getInvoiceList(companyId, searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("billing/Invoices?CompanyId=" + companyId + "&search=" +  encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getInvoiceDetail(invoiceId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("billing/Invoices/" + invoiceId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  downloadInvoice(invoiceId): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("billing/Invoices/" + invoiceId + "/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }



  //Client Faq list
  getClientFaqs(searchText): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Faqs/User?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }



  //Categories
  getCategories(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Categories?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  deleteCategories(categoryIds) {
    return this._mainService.callWebserviceHttpDelete("Categories/", categoryIds, localStorage.authenticationKey);
  }

  getCategoryDetails(categoryId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Categories/" + categoryId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postCategory(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Categories?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putCategory(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Categories?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //Suggestions
  getSuggestions(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Suggestions?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  deleteSuggestions(suggestionIds) {
    return this._mainService.callWebserviceHttpDelete("Suggestions/", suggestionIds, localStorage.authenticationKey);
  }

  getSuggestionDetails(suggestionId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Suggestions/" + suggestionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postSuggestion(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Suggestions?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putSuggestion(data, suggestionId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Suggestions/" + suggestionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }


  //HelpText
  getHelpTexts(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("HelpText?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  deleteHelpTexts(helpTextIds) {
    return this._mainService.callWebserviceHttpDelete("HelpText/", helpTextIds, localStorage.authenticationKey);
  }

  getHelpTextDetails(helpTextId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("HelpText/" + helpTextId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postHelpText(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("HelpText?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  putHelpText(data, helpTextId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("HelpText/" + helpTextId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }




 
  //Kvk verify
  CheckKvK(kvkId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies/KvkNumber/" + kvkId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  


  //Registrattion
  registration(data, resendEmail): Promise<any> {
    return this._mainService.callWebserviceHttpPost("signUp?resendEmail="+ resendEmail , data, localStorage.authenticationKey);
  }

  verifyEmail(data): Promise<any> {
    return this._mainService.callWebserviceHttpPatch("Activate", data, localStorage.authenticationKey);
  }

  verify2faLinkedSend(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Reset2Fa", data, localStorage.authenticationKey);
  }

  getSubsriptionSettings(id,localid,setting,searchText, orderBy, orderDir, page, pageSize): Promise<any>{
    return this._mainService.callWebserviceHttpGetForRegister("DomainSubscriptions/List?domainId="+ id +"&loggedInDomainId=" +  localid + "&search=" + encodeURIComponent(searchText) + "&setting=" + setting + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043)+ "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize);
  }

  getSubsriptionsList(searchText, orderBy, orderDir, page, pageSize): Promise<any>{
    return this._mainService.callWebserviceHttpGet("DomainSubscriptions?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043)+ "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey );
  }

  getSubsriptionDetail(domainSubscriptionId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DomainSubscriptions/" + domainSubscriptionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  postSubsription(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("DomainSubscriptions?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //contract company
  getContractCompany(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ContractCompanies?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getContractCompanyDetail(companyId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ContractCompanies/" + companyId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getContractCompanyDetailWizard(companyId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ContractCompanies/Wizard/" + companyId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  // putContractCompany(data): Promise<any> {
  //   return this._mainService.callWebserviceHttpPut("ContractCompanies?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  // }

  postContractCompany(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ContractCompanies?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deletContractCompany(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("ContractCompanies/", ids, localStorage.authenticationKey);
  }

  //contract person
  getContractPersons(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ContractPersons?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getContractPerson(companyId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ContractPersons/" + companyId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  // putContractPerson(data): Promise<any> {
  //   return this._mainService.callWebserviceHttpPut("ContractPersons?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  // }

  postContractPerson(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ContractPersons?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  deletContractPerson(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("ContractPersons/", ids, localStorage.authenticationKey);
  }

  //help needed and premium
  // getHelpDetail(versionId): Promise<any> {
  //   return this._mainService.callWebserviceHttpGet("Settings/Help/" + versionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  // }
  getHelpDetail(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Settings/Help?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getPremiumDetail(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Settings/Premium?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getHelpImage(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Settings/Help/Images/Download?IsMaintenece=true" + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getCurrentHelpImage(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Settings/Help/Images/Download?IsMaintenece=false" + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getPremiumImage(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Settings/Premium/Images/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  putHelp(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Settings/Help?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  putPremium(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Settings/Premium?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  putHelpImage(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Settings/Help/Images?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  putPremiumImage(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Settings/Premium/Images?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  deleteHelpImage(data): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Settings/Help/Images/", data, localStorage.authenticationKey);
  }
  deletePremiumImage(data): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("Settings/Premium/Images/", data, localStorage.authenticationKey);
  }

  getHeaderImage(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Settings/Help/Images/Download?IsMaintenece=true&IsHeader=true" + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  putHeaderImage(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("Settings/Help/Images?IsHeader=true&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }


  deleteHeaderImage(data): Promise<any> {
    return this._mainService.callWebserviceHttpDeleteHeaderImage("Settings/Help/Images/", data, localStorage.authenticationKey);
  }

  getCurrentHeaderImage(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Settings/Help/Images/Download?IsMaintenece=false&IsHeader=true" + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }


  //document generator

  postDocument(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("Documents", data, localStorage.authenticationKey);
  }

  getDocumentValues(id, versionId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentValues?documentId=" + id + "&documentVersionId=" + versionId, localStorage.authenticationKey)
  }

  postDocumentValues(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("DocumentValues", data, localStorage.authenticationKey);
  }

  deleteDocumentValues(id): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("DocumentValues/", id, localStorage.authenticationKey);
  }

  completeDocument(id, versionId): Promise<any> {
    return this._mainService.callWebserviceHttpPatch("Documents/" + id + "?documentVersionId=" + versionId, {}, localStorage.authenticationKey);
  }

  completeDocumentVersion(id): Promise<any> {
    return this._mainService.callWebserviceHttpPatch("DocumentVersions/" + id, {}, localStorage.authenticationKey);
  }

  putDocumentBookmark(id, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("DocumentTemplates-Fields/" + id, data, localStorage.authenticationKey);
  }

 
  //document library
  getDocumentLibrary(searchText, sortOrder,typeLabel,orderBy,orderDir,page, pageSize, section, filterId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentLibrary?" + section + "=1" + "&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&sortOrder=" + sortOrder + "&documentType=" + typeLabel + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&filterType=" + filterId , localStorage.authenticationKey);
  }
  
  getLibraryStandardDocuments(searchText, sortOrder,typeLabel, page, pageSize, wizard, filterId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("StandardDocuments?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&sortOrder=" + sortOrder + "&documentType=" + typeLabel + "&page=" + page + "&pageSize=" + pageSize + "&wizard=" + wizard + "&filterType=" + filterId, localStorage.authenticationKey);
  }
  deleteDocuments(documentId) {
    return this._mainService.callWebserviceHttpDelete("Documents/", documentId, localStorage.authenticationKey);
  }

  getDocumentLibraryDetails(documentId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentLibrary/" + documentId  + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getDownloadDocumentLibrary(documentId, versionId, module, check): Promise<any> {
    if(module == "Module_Quickscan") {
      return this._mainService.callWebserviceHttpGetFile("QuickScanForm/" + documentId + "/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
    } else if(module == "Module_CustomDocument") {
      return this._mainService.callWebserviceHttpGetFile("DocumentLibrary/" + documentId + "/Download?documentVersionId=" + versionId + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
    }  else {
      return this._mainService.callWebserviceHttpGetFile("Documents/" + documentId + "/Versions/" + versionId + "/Download?isLocked=" + check + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
    }
  }

  getDownloadStandardDocument(documentId): Promise<any> {
      return this._mainService.callWebserviceHttpGetFile("StandardDocuments/" + documentId + "/Download?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  // putDuplicateDocument(id, data): Promise<any> {
  //   return this._mainService.callWebserviceHttpPost("DocumentLibrary/" + id + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  // }
  putDocumentDetail(id, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("DocumentLibrary/" + id + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  putAdjustment(id, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("DocumentLibrary/" + id + "?action=Adjustment" + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  // patchUploadData(data, id): Promise<any> {
  //   return this._mainService.callWebserviceHttpPatch("DocumentLibrary/" + id, data, localStorage.authenticationKey);
  // }


  patchUploadOrDuplicateData(data, id, status, module): Promise<any> {
    if(id == '0' || module == 'Module_CustomDocument') {
    return this._mainService.callWebserviceHttpFormPost("DocumentLibrary?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
    } else {
      return this._mainService.callWebserviceHttpFormPatch("DocumentLibrary/" + id + "?isDuplicate=" + status + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
    }
  }
  postLibraryFovouriteDocument(data, id, section): Promise<any> {
    if(section == "standard") {
      return this._mainService.callWebserviceHttpPut("FavouriteStandardDocument/" + id , data, localStorage.authenticationKey);
    } else {
      return this._mainService.callWebserviceHttpPut("FavouriteDocument/" + id , data, localStorage.authenticationKey);
    }
  }


  putAskDocQuestionChat(id, data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("DocumentLibrary/OpenTicket/" + id, data, localStorage.authenticationKey);
  }

  getDocumentVersions(documentId, searchText, orderBy, orderDir): Promise<any> {
    return this._mainService.callWebserviceHttpGet("DocumentLibrary/" + documentId + "?IsHistory=true" + "&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir , localStorage.authenticationKey)
  }

  

  getDownloadDocumentReport(id): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("Documents/" + id + "/Download", localStorage.authenticationKey);
  }
  
  getLegalForm(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("LegalForms?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getNationalities(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Nationalities?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  //standard dcuments
  getStandardDocuments(searchText, orderBy, orderDir, page, pageSize, wizard): Promise<any> {
    return this._mainService.callWebserviceHttpGet("StandardDocuments?isMaintenece=1&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize + "&wizard=" + wizard, localStorage.authenticationKey);
  }
  getStandardDocumentDetail(standardId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("StandardDocuments/" + standardId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  postStandardDocument(data): Promise<any> {
    return this._mainService.callWebserviceHttpFormPost("StandardDocuments?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  deleteStandardDocuments(standardId) {
    return this._mainService.callWebserviceHttpDelete("StandardDocuments/", standardId, localStorage.authenticationKey);
  }
  
  postFovouriteDocument(data, id): Promise<any> {
    return this._mainService.callWebserviceHttpPost("DocumentTemplates/" + id + "/Favourites", data, localStorage.authenticationKey);
  }
  
  deleteFavouriteTemplateDocument(ids): Promise<any> {
    return this._mainService.callWebserviceHttpDelete("FavortieUserDocumentTempletes/", ids, localStorage.authenticationKey);
  }

  checkCustomDataType(templateId, versionId, dataType, dataTypeId) {
    return this._mainService.callWebserviceHttpGet("DocumentTemplates/" + templateId + "/Versions/" + versionId + "/DataType?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&customType=" + dataType + "&customTypeId=" + dataTypeId, localStorage.authenticationKey);
  }

  //knowledgebase
  getKnowledgebase(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("KnowledgeBaseItems?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getKnowledgebaseTree(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("KnowledgeBaseItems/tree" + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getKnowledgeBaseDetails(id, status): Promise<any> {
    return this._mainService.callWebserviceHttpGet("KnowledgeBaseItems/" + id + "?isKnowledgeBaseCenter=" + status+ "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  deleteKnowledgeBase(id) {
    return this._mainService.callWebserviceHttpDelete("KnowledgeBaseItems/", id, localStorage.authenticationKey);
  }

  postKnowledgeBase(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("KnowledgeBaseItems?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  getKnowledgebasePerCategory(id, searchText, page, pageSize, categoryPage, categoryPageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("KnowledgeBaseItems/Category?categoryId=" + id + "&search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&page=" + page + "&pageSize=" + pageSize+ "&pageCategory=" + categoryPage + "&pageSizeCategory=" + categoryPageSize, localStorage.authenticationKey);
  }


  getOfflineSetting(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("OfflineSettings?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }


  previewDocument(documentId, documentVersionId): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("Documents/" + documentId + "/Versions/" + documentVersionId + "/Preview?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  //document wizard 
  getContractCompanyWizard(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ContractCompanies/Wizard?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  getCompaniesWizard(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Companies/Wizard?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  //LinkRequest
  getLinkRequest(){
    return this._mainService.callWebserviceHttpGet("Links?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  
  updateSortOrder(data): Promise<any> {
    return this._mainService.callWebserviceHttpPut("KnowledgeBaseItems?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  exportExcelFile(): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("Users/Export?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }

  //question of the week
  getQuestionOfTheWeek(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("QuestionOfTheWeek?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }
  getQuestionOfTheWeekDetail(questionId): Promise<any> {
    return this._mainService.callWebserviceHttpGet("QuestionOfTheWeek/" + questionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  postQuestionOfTheWeekDetail(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("QuestionOfTheWeek?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  putQuestionOfTheWeekDetail(data, questionId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("QuestionOfTheWeek/" + questionId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  
  deleteQuestionOfTheWeekDetail(questionId) {
    return this._mainService.callWebserviceHttpDelete("QuestionOfTheWeek/", questionId, localStorage.authenticationKey);
  }
  getCurrentQuestion(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("QuestionOfTheWeek/Current?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) , localStorage.authenticationKey);
  }

  // Register of processing activities

  getProcessingActivityDocuments(searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ProcessingActivityDocuments?search=" + encodeURIComponent(searchText) +"&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getProcessingActivityTypes(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ProcessingActivityTypes?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getProcessingActivityLegalBasis(): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ProcessingActivityLegalBasis?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
  getProcessingActivityTypesDetail(Id, searchText, orderBy, orderDir, page, pageSize): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ProcessingActivityDocuments/" + Id + "?search=" + encodeURIComponent(searchText) + "&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043) + "&orderBy=" + orderBy + "&orderDir=" + orderDir + "&page=" + page + "&pageSize=" + pageSize, localStorage.authenticationKey);
  }

  getProcessingActivityRecords(Id): Promise<any> {
    return this._mainService.callWebserviceHttpGet("ProcessingActivityRecords/" + Id + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
//post processing records
postProcessingActivityDocuments(data): Promise<any> {
  return this._mainService.callWebserviceHttpPost("ProcessingActivityDocuments?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
}
putProcessingActivityDocuments(data, activityDocumentsId): Promise<any> {
  return this._mainService.callWebserviceHttpPut("ProcessingActivityDocuments/" + activityDocumentsId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
}

//delete processing records
deleteProcessingActivityDocuments(activityDocumentsId) {
  return this._mainService.callWebserviceHttpDelete("ProcessingActivityDocuments/", activityDocumentsId, localStorage.authenticationKey);
}

//post register records
  postProcessingActivityRecords(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("ProcessingActivityRecords?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }
  putProcessingActivityRecords(data, activityRecordsId): Promise<any> {
    return this._mainService.callWebserviceHttpPut("ProcessingActivityRecords/" + activityRecordsId + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), data, localStorage.authenticationKey);
  }

  //delete register records
  deleteProcessingActivityRecords(activityRecordsId) {
    return this._mainService.callWebserviceHttpDelete("ProcessingActivityRecords/", activityRecordsId, localStorage.authenticationKey);
  }

  //download for pocessing activities
  getDownloadProcessingActivityDocument(documentId): Promise<any> {
    return this._mainService.callWebserviceHttpGetFile("ProcessingActivityDocuments/"+ documentId + "/Export?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), localStorage.authenticationKey);
  }
}