import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { ApiService } from '../../../services/api.service';
import { DatePipe } from '@angular/common';

import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';

import { MomentDateTimeAdapter } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import * as moment from "moment"
import { AlertController, ModalController } from '@ionic/angular';


export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD-MM-YYYY HH:mm',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-add-contractperson',
  templateUrl: './add-contractperson.component.html',
  styleUrls: ['./add-contractperson.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class AddContractpersonComponent implements OnInit {
  contractPersonForm: FormGroup;
  submitted = false;
  ContractPersonId: string;
  contractPersonLocale: any = {};
  contractPerson: any = {};
  tempContractPerson: any = {}
  showPage: boolean = false;

  locale: any;
  tempLocale: any;
  patternError: boolean = false

  isThirdCase: boolean = false

  controlStatus: any = {
    ZipCode: false,
    EmailAddress: false,
    PhoneNumber: false,
    MobileNumber: false,
    BankAccount: false,
  };

  formValidate: boolean = true

  isNewContractPerson: boolean = false
  templateRequiredField: any = []

  startDate: any;
  endDate: any;
  companyName: any = null;
  disableCompany: boolean = false;
  birthDate: any;
  Companies: any = [];
  ContractCompany: any = [];
  NationalitiesList: any = [];

  public max = new Date(moment().format());

  config: any = {
    fontNames: ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Helvetica', 'Roboto', 'sans-serif;', 'Times New Roman', 'Verdana'],
    height: 300,
    toolbar: [
      ['headline', ['style']],
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['picture','video', 'link']],
      ['table', ['table']]
    ]
  };
  contractPersonList: Array<any> = [];
  @Input() id: string;
  @Input() customField: [];
  @Input() versionId: string;
  @Input() templateId: string;
  @Input() dataType: string;

  constructor(private el: ElementRef,private alertController: AlertController,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private datePipe: DatePipe, private formBuilder: FormBuilder, private _apiService: ApiService, public _commonService: CommonService, private modalController: ModalController) { }

  async ngOnInit() {
    this.contractPersonForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Gender: [''],
      BirthDate: [''],
      EmailAddress: ['', Validators.email],
      ContractCompanyId: [''],
      CompanyId: [''],
      Status: ['1'],
      PhoneNumber: ['', [Validators.pattern(this._commonService.phonePattern)]],
      MobileNumber: ['', [Validators.pattern(this._commonService.phonePattern)]],
      Department: [''],
      Function: [''],
      Infix: [''],
      Initials: [''],

      City: [''],
      Country: [''],
      Street: [''],
      StreetNumber: [''],
      NationalityId: [''],
      BankAccount: ['', [Validators.pattern(this._commonService.bankAccountpattern)]],
      ZipCode: ['', [Validators.pattern(this._commonService.postalCodePattern)]],

    });


    this.disableCompany = false;
    this.ContractPersonId = this.id;

    this.locale = Number((localStorage.locale && localStorage.locale != '') ? localStorage.locale : 1043);
    if(this.locale == '1043'){
      this.dateTimeAdapter.setLocale('nl');
      this.config.lang = 'nl-NL';
    }else {
      this.dateTimeAdapter.setLocale('en');
    }
    if (this.ContractPersonId == '') {
      this.ContractPersonId = null;
    }
    if (this.ContractPersonId) {
      this.templateRequiredField = this.customField
      this.isNewContractPerson = false
      await this.getcontractPersonDetails();

    } else {
      this.templateRequiredField = []
      this.isNewContractPerson = true
      await this.getCompanies();
      await this.getContractCompany();
      this.getLocales();
      this.getNationalities();
      this.showPage = true;
      this.contractPerson =
      {
        ContractPersonId: this.ContractPersonId,
        FirstName: '',
        LastName: ',',
        Gender: '',
        BirthDate: '',
        EmailAddress: '',
        Status: '1',
        PhoneNumber: '',
        ContractCompanyId: '',
        CompanyId: '',
        MobileNumber: '',
        Department: '',
        Function: '',
        Infix: '',
        Initials: '',

        City: '',
        Country: '',
        Street: '',
        StreetNumber: '',
        ZipCode: '',
        NationalityId: '',
        BankAccount: '',
        Translations: [
          {
            Title: "",
            Description: "",
            LocaleId: this.locale,
          }
        ]
      }
      if(this.contractPersonForm.value && this.contractPersonForm.value.NationalityId == "") {
        this.contractPersonForm.patchValue({
          NationalityId: '1b0296eb-045c-49b4-a91c-d1c22e4a4349',
        });
      }
    }
  }

  async getLocales() {
    try {
      if (localStorage.domainLocales) {
        this.contractPersonLocale.AllLocales = JSON.parse(localStorage.domainLocales);
      } else {
        let localeData = await this._apiService.getDomainLocales('', 1, 0);
        this.contractPersonLocale.AllLocales = localeData.body;
        localStorage.domainLocales = JSON.stringify(localeData.body);
      }
    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async getcontractPersonDetails() {
    try {
      this._commonService.presentLoading();
      let res = await this._apiService.getContractPerson(this.ContractPersonId);
      this.contractPerson = res.body;
      this.tempContractPerson = res.body
      await this.getLocales();
      await this.getCompanies();
      await this.getContractCompany();
      await this.getNationalities();
      this._commonService.log(this.contractPerson, this._commonService.LOG);
      this.contractPersonForm.controls['Status'].reset();

      this.contractPersonForm.patchValue({
        City: this.contractPerson.City,
        Country: this.contractPerson.Country,
        Street: this.contractPerson.Street,
        StreetNumber: this.contractPerson.StreetNumber,
        ZipCode: this.contractPerson.ZipCode,
        Gender: this.contractPerson.Gender,
        EmailAddress: this.contractPerson.EmailAddress,
        ContractCompanyId: this.contractPerson.ContractCompanyGUID,
        CompanyId: this.contractPerson.CompanyGUID,
        Status: Number(this.contractPerson.Status) ? true : false,
        // BirthDate: this.contractPerson.BirthDate? this.datePipe.transform(new Date(this.contractPerson.BirthDate), "dd-MM-yyyy") : null,
        FirstName: this.contractPerson.FirstName,
        LastName: this.contractPerson.LastName,
        MobileNumber: this.contractPerson.MobileNumber,
        PhoneNumber: this.contractPerson.PhoneNumber,
        Department: this.contractPerson.Department,
        Function: this.contractPerson.Function,
        Infix: this.contractPerson.Infix,
        Initials: this.contractPerson.Initials,
        NationalityId: this.contractPerson.NationalityId,
        BankAccount: this.contractPerson.BankAccount,
      });
      if(!this.contractPersonForm.value.NationalityId || this.contractPersonForm.value.NationalityId == "") {
        // let nationality = this.NationalitiesList.filter( e => e.NationalityId == '1b0296eb-045c-49b4-a91c-d1c22e4a4349');
        this.contractPersonForm.patchValue({
          NationalityId: '1b0296eb-045c-49b4-a91c-d1c22e4a4349',
        });
      }
      this.contractPersonForm.updateValueAndValidity();

      this.checkTemplateField()

      this.showPage = true;
      this._commonService.dismiss();
    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR)
    }
  }


  checkTemplateField(){
    if(this.templateRequiredField.length > 0){
      this.templateRequiredField.forEach(field => {
        Object.keys(this.contractPersonForm.controls).forEach(key => {
            if(key.toLowerCase() == field){
              if(field == 'emailaddress'){
                this.contractPersonForm.controls[key].setValidators([Validators.required, Validators.email])
              }else if(field == 'phonenumber'){
                this.contractPersonForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.phonePattern)])
              }else if(field == 'bankaccount'){
                this.contractPersonForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.bankAccountpattern)])
              }else if(field == 'zipcode'){
                this.contractPersonForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.postalCodePattern)])
              }else if(field == 'mobilenumber'){
                this.contractPersonForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.phonePattern)])
              }else{
                this.contractPersonForm.controls[key].setValidators(Validators.required)
              }

              this.contractPersonForm.controls[key].setErrors({'required': true})
              this.contractPersonForm.controls[key].markAsTouched();
              this.contractPersonForm.updateValueAndValidity()
              this.controlStatus.ZipCode = true
              this.controlStatus.EmailAddress = true
              this.controlStatus.PhoneNumber = true
              this.controlStatus.MobileNumber = true
              this.controlStatus.BankAccount = true
            }
        });

      });

    }
  }

  async getCompanies() {
    try {
      this._commonService.presentLoading();
      let companyData = await this._apiService.getCompanies('', '', '', 1, 0);
      this.Companies = companyData.body;
      this._commonService.dismiss();
    }
    catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async getContractCompany() {
    try {
      this._commonService.presentLoading();
      let ContractCompanyData = await this._apiService.getContractCompany('', '', '', 1, 0);
      this.ContractCompany = ContractCompanyData.body;
      this._commonService.dismiss();
    }
    catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async getNationalities() {
    try {
      if (localStorage.NationalitiesList) {
        this.NationalitiesList = JSON.parse(localStorage.NationalitiesList);
      } else {
        let nationalities = await this._apiService.getNationalities();
        this.NationalitiesList = nationalities.body;
        localStorage.setItem('NationalitiesList', JSON.stringify(nationalities.body));
      }
    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  onBlurMethod(controlName) {
    if (controlName == 'ZipCode') {
      this.controlStatus.ZipCode = true
    }
    if (controlName == 'EmailAddress') {
      this.controlStatus.EmailAddress = true
    }
    if (controlName == 'PhoneNumber') {
      this.controlStatus.PhoneNumber = true
    }
    if (controlName == 'MobileNumber') {
      this.controlStatus.MobileNumber = true
    }
    if (controlName == 'BankAccount') {
      this.controlStatus.BankAccount = true
    }
  }

  onFocusMethod(controlName) {
    if (controlName == 'ZipCode') {
      this.controlStatus.ZipCode = false
    }
    if (controlName == 'EmailAddress') {
      this.controlStatus.EmailAddress = false
    }
    if (controlName == 'PhoneNumber') {
      this.controlStatus.PhoneNumber = false
    }
    if (controlName == 'MobileNumber') {
      this.controlStatus.MobileNumber = false
    }
    if (controlName == 'BankAccount') {
      this.controlStatus.BankAccount = false
    }
  }


  get f() { return this.contractPersonForm.controls; }

  async onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contractPerson.BirthDate) {
      this.contractPersonForm.patchValue({
        BirthDate: this.datePipe.transform(new Date(this.contractPerson.BirthDate), "dd-MM-yyyy"),
      })
    }

    if(this.contractPersonForm.controls['FirstName'].valid &&  this.contractPersonForm.controls['LastName'].valid ){
      this.formValidate = true
    }else{
      this.formValidate = false
    }


    if (this.contractPersonForm.invalid && !this.formValidate ) {
      // this._commonService.scrollToError();
      this.controlStatus.ZipCode = true;
      this.controlStatus.EmailAddress = true;
      this.controlStatus.PhoneNumber = true;
      this.controlStatus.MobileNumber = true;
      this.controlStatus.BankAccount = true;
      setTimeout(() => {
        let firstInvalidControl;
        firstInvalidControl = this.el.nativeElement.querySelector(
          "form .is-invalid"
        );
        if (!firstInvalidControl && this.contractPersonForm.controls.BirthDate.value == "") {
          firstInvalidControl = document.querySelector('input.is-valid');
          console.log(firstInvalidControl);
        }
        if(firstInvalidControl){
          firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 300);

      return;
    }else if(this.contractPersonForm.invalid && this.formValidate && !this.isThirdCase){
      if((this.contractPersonForm.controls['ZipCode'].errors && this.contractPersonForm.controls['ZipCode'].errors.pattern)
      || (this.contractPersonForm.controls['EmailAddress'].errors && this.contractPersonForm.controls['EmailAddress'].errors.pattern)
      || (this.contractPersonForm.controls['PhoneNumber'].errors && this.contractPersonForm.controls['PhoneNumber'].errors.pattern)
      || (this.contractPersonForm.controls['MobileNumber'].errors && this.contractPersonForm.controls['MobileNumber'].errors.pattern)
      || (this.contractPersonForm.controls['BankAccount'].errors && this.contractPersonForm.controls['BankAccount'].errors.pattern)
      ){
      this.patternError = true
    }else{
      this.patternError = false
    }
    if(!this.patternError){
      this.openAlert()
    }
    }else{
      this.saveDetail()
  }
  }

  async saveDetail(){

    this._commonService.presentLoading();
    try {
      this.contractPerson.FirstName = this.contractPersonForm.value.FirstName;
      this.contractPerson.LastName = this.contractPersonForm.value.LastName;

      this.contractPerson.Gender = this.contractPersonForm.value.Gender;
      this.contractPerson.EmailAddress = this.contractPersonForm.value.EmailAddress.trim();
      this.contractPerson.Status = this.contractPersonForm.value.Status ? '1' : '0';
      this.contractPerson.Department = this.contractPersonForm.value.Department;
      this.contractPerson.Function = this.contractPersonForm.value.Function;
      this.contractPerson.BirthDate = this.contractPerson.BirthDate ? this.datePipe.transform(new Date(this.contractPerson.BirthDate), "yyyy-MM-dd") : null,
        this.contractPerson.MobileNumber = this.contractPersonForm.value.MobileNumber;
      this.contractPerson.PhoneNumber = this.contractPersonForm.value.PhoneNumber;
      this.contractPerson.ContractCompanyId = this.contractPersonForm.value.ContractCompanyId != '1' ? this.contractPersonForm.value.ContractCompanyId : "";
      this.contractPerson.CompanyId = this.contractPersonForm.value.CompanyId != '1' ? this.contractPersonForm.value.CompanyId : "";
      this.contractPerson.ContractPersonId = this.ContractPersonId ? this.ContractPersonId : null;

      this.contractPerson.Initials = this.contractPersonForm.value.Initials;
      this.contractPerson.Infix = this.contractPersonForm.value.Infix;
      this.contractPerson.Country = this.contractPersonForm.value.Country;
      this.contractPerson.Street = this.contractPersonForm.value.Street;
      this.contractPerson.StreetNumber = this.contractPersonForm.value.StreetNumber;
      this.contractPerson.City = this.contractPersonForm.value.City;
      this.contractPerson.ZipCode = this.contractPersonForm.value.ZipCode;
      this.contractPerson.NationalityId = this.contractPersonForm.value.NationalityId;
      this.contractPerson.BankAccount = this.contractPersonForm.value.BankAccount;

      let res = await this._apiService.postContractPerson(this.contractPerson);

      if (res) {
        let response = await this._apiService.checkCustomDataType(this.templateId, this.versionId, this.dataType, res.id)
        if (res.id  && response.body.fields.length == 0) {
          this.ContractPersonId = res.id;
          await this.getContractPersons();
          this.closeModal(false);
          this.isThirdCase = false
        }else{
          this.ContractPersonId = res.id;
          await this.getContractPersons();
          this.templateRequiredField = response.body.fields
          await this.checkTemplateField();
          if(!this.isThirdCase){
            this.openAlert()
          }



        }
      }
      this._commonService.dismiss();
      this._commonService.showToast(this._commonService.getLocalTranslation('label-recordSaved'));
      this._commonService.log(this.contractPerson, this._commonService.LOG);
      this.submitted = false;
      // save to localstorage
      //this._commonService.getcontractPerson();
    } catch (error) {
      if (error.status >= 500 && error.status < 600) {
        this.contractPersonForm.patchValue({
          City: this.tempContractPerson.City,
          Country: this.tempContractPerson.Country,
          Street: this.tempContractPerson.Street,
          StreetNumber: this.tempContractPerson.StreetNumber,
          ZipCode: this.tempContractPerson.ZipCode,
          Gender: this.tempContractPerson.Gender,
          EmailAddress: this.tempContractPerson.EmailAddress,
          ContractCompanyId: this.tempContractPerson.ContractCompanyGUID,
          CompanyId: this.tempContractPerson.CompanyGUID,
          Status: Number(this.tempContractPerson.Status) ? true : false,
          // BirthDate: this.contractPerson.BirthDate? this.datePipe.transform(new Date(this.contractPerson.BirthDate), "dd-MM-yyyy") : null,
          FirstName: this.tempContractPerson.FirstName,
          LastName: this.tempContractPerson.LastName,
          MobileNumber: this.tempContractPerson.MobileNumber,
          PhoneNumber: this.tempContractPerson.PhoneNumber,
          Department: this.tempContractPerson.Department,
          Function: this.tempContractPerson.Function,
          Infix: this.tempContractPerson.Infix,
          Initials: this.tempContractPerson.Initials,
          NationalityId: this.tempContractPerson.NationalityId,
          BankAccount: this.tempContractPerson.BankAccount,
        });

      }
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  onChangeBreadcrumb() {
    this.contractPerson.FirstName = this.contractPersonForm.value.FirstName;
  }

  BirthDateTimeChange(event) {
    this.contractPersonForm.patchValue({
      BirthDate: this.datePipe.transform(new Date(event.value), "dd-MM-yyyy"),
    })
  }

  async openAlert(){

    setTimeout( async() => {
      let firstInvalidControl;
      firstInvalidControl = this.el.nativeElement.querySelector(
        "form .is-invalid"
      );
      if (!firstInvalidControl && this.contractPersonForm.controls.BirthDate.value == "") {
        firstInvalidControl = document.querySelector('input.is-invalid');
      }
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });

      setTimeout( async() => {

        const alert = await this.alertController.create({
          header: '',
          message: this._commonService.getLocalTranslation('label-templateValidationError'),
          buttons: [
            {
              text: this._commonService.getLocalTranslation('label-ok'),
              handler: async () => {
                this.isThirdCase = true
                  await this.saveDetail();
                  this.closeModal(false);


              }
            },
            {
              text: this._commonService.getLocalTranslation('label-cancel'),
              role: 'cancel',
              handler: () => {
              }
            }


          ]
        });
        await alert.present();
      }, 200);
    }, 300);
  }

  async getContractPersons() {
    try {
      this._commonService.presentLoading();
      let res = await this._apiService.getContractPersons('','FirstName','ASC',0,0);
      this.contractPersonList = res.body;
      this._commonService.dismiss();
    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async closeModal(closed) {
    let obj = {};
    if(this.ContractPersonId) {
      obj['id'] = this.ContractPersonId;
    }
    if(this.contractPersonList && this.contractPersonList.length > 0) {
      obj['list'] = this.contractPersonList;
    }
    obj['closed'] = closed;
    this.modalController.dismiss(obj);
  }


}
