import { Component, OnInit, ViewChild, ElementRef, SimpleChanges, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-searchpopover',
  templateUrl: './searchpopover.component.html',
  styleUrls: ['./searchpopover.component.scss'],
})
export class SearchpopoverComponent {
  
  // @ViewChild('focusInput', {static:false}) input ;

  @Input() value;

  constructor(public _commonService: CommonService, private popoverController: PopoverController) {
    
  }

  searchText(form: NgForm){
    this._commonService.searchTextValue.next(form.value.search);
    this.dismiss();
  }

  ngOnInit(): void {
    console.log(this.value)
  }

  async dismiss() {
    await this.popoverController.dismiss();
  }

  // focus element after rendered on DOM
  ngAfterViewChecked(): void {
    // setTimeout(() => {
    //   this.input.setFocus();
    // },5);
  }
  
}
