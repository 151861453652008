import { DatePipe, Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { TranslationService } from 'src/app/services/translation.service';
declare function openChat(username: string, userEmail: string, teamleaderGUID: string, documentGUID: string):any;

@Component({
  selector: 'app-quickscan-result',
  templateUrl: './quickscan-result.component.html',
  styleUrls: ['./quickscan-result.component.scss'],
})
export class QuickscanResultComponent implements OnInit {
  @Input() result: any;
  @Input() count: any;
  @Input() showUpgradeButton: boolean;
  @Input() showTrengoChat: boolean;
  @Input() isStartPage: boolean;
  @Input() quickscanName: string = '';
  moduleList: any = []

  constructor(private datePipe: DatePipe,private location: Location, private _formBuilder: FormBuilder, private _translationService: TranslationService, private router: Router, private route: ActivatedRoute, private _apiService: ApiService,private formBuilder: FormBuilder, public _commonService: CommonService, private popoverController: PopoverController, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
   this.getModuleList()
  }

  gotoSubscription(){
    this._commonService.isFromQuickScan = true
    this.router.navigate(['maintenance/domains/',localStorage.domainId]);
  
}

startTrengoChat(){
  var email = localStorage.userName ? localStorage.userName : "Unknown";
  var firstName = localStorage.firstName ? localStorage.firstName : "Unknown";
  var teamleaderGUID = localStorage.TeamLeaderGuid ?  localStorage.TeamLeaderGuid : 'Unknown';
   openChat(firstName, email,teamleaderGUID, sessionStorage.QuickScanId);
}

async goToModuleLink(id,RefExternal){
  let k = this.moduleList.filter(x=>x.guid==id);
  if(k){
    if(k[0].name_systemtranslationcode=='Module_FAQ'){
      if(RefExternal!=null){
        // this.router.navigate(['/frequentlyaskedquestions/' + RefExternal]);
        if (this.route.snapshot['_routerState'].url != '/frequentlyaskedquestions') {
          let faq = {
            CategoryId: RefExternal[0].CategoryId,
            FaqId : RefExternal[0].RefExternalId
          }
          if (this._commonService.isLoggedIn && localStorage.domainName != 'MASTER') {
            const navigationExtras: NavigationExtras = {
              state: { faq }
            };
            this.router.navigate(['frequentlyaskedquestions'], navigationExtras);
          }
        }
      } else {
        this.router.navigate(['/frequentlyaskedquestions']);
      }
      
    }else if(k[0].name_systemtranslationcode=='Module_DocumentGenerator'){
      if(RefExternal!=null){
        this.router.navigate(['/documentgenerator/wizard/' + RefExternal[0].RefExternalId + '/' + RefExternal[0].DocumentTemplateVersionId]);
      } else {
        this.router.navigate(['/documentgenerator']);
      }
    }else if(k[0].name_systemtranslationcode=='Module_StandardDocument'){
      if(RefExternal!=null){
        this.router.navigate(['/documentlibrary/' + 'standarddocument/' + RefExternal[0].RefExternalId + "/" + localStorage.domainId]);
      } else {
        this.router.navigate(['/documentlibrary']);
      }

    }else if(k[0].name_systemtranslationcode=='Module_Quickscan'){
      if(RefExternal!=null){
        this.router.navigate(['/documentlibrary/' + 'documents/' + RefExternal[0].RefExternalId + "/" + localStorage.domainId]);
      } else {
        this.router.navigate(['/documentlibrary']);
      }
    }else if(k[0].name_systemtranslationcode=='Module_Notification'){
      if(RefExternal!=null){
        this.router.navigate(['/notifications/' + RefExternal]);

      } else {
        this.router.navigate(['/notifications']);
      }

    }else if(k[0].name_systemtranslationcode=='Module_Messageboard'){
      if(RefExternal!=null){
        this.router.navigate(['maintenance/messages/' + RefExternal]);
      } else {
        this.router.navigate(['/frequentlyaskedquestions']);
      }

    }
  }
}

async getModuleList(){
  try{
    if(localStorage.moduleList){
      this.moduleList = JSON.parse(localStorage.moduleList)
    }else{
      let moduleList = await this._apiService.getModules('', '', '', 1, 0);
      this.moduleList = moduleList.body
      localStorage.moduleList = JSON.stringify(moduleList.body);
    }

  }catch(error){
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
  }
}

async downloadFile(){
  try {
    event.stopPropagation();
    this._commonService.presentLoading();
    
      let res = await this._apiService.downloadQuickScanReport(sessionStorage.QuickScanFormId);
      this._commonService.dismiss();
     let  blob = new Blob([res.body])
    const url = window.URL.createObjectURL(blob);
    let name = 'quickscanreport_' + this.datePipe.transform(new Date(), "yyyyMMddHHmmss")
    const fileName = name + '.pdf';
    
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = res.headers.get('x-blob-filename')?res.headers.get('x-blob-filename'):fileName;
    downloadLink.click();
    blob = new Blob([res.body], { type: 'application/pdf' });
    let newWindow = window.open('', "_blank");
    newWindow.location.href = URL.createObjectURL(blob);
    this._commonService.dismiss();
  } catch (error) {
    this._commonService.dismiss();
    this._commonService.log(error, this._commonService.ERROR);
  }
}



}
