import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { ApiService } from '../../../services/api.service';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-contractcompany',
  templateUrl: './add-contractcompany.component.html',
  styleUrls: ['./add-contractcompany.component.scss'],
})
export class AddContractcompanyComponent implements OnInit {
  contractComopanyForm: FormGroup;
  submitted = false;
  contractCompanyId: string;
  contractCompanyLocale: any = {};
  contractCompany: any = {};
  tempContractCompany: any = {}
  showPage: boolean = false;
  patternError: boolean = false

  locale: any;
  tempLocale: any;
  formValidate: boolean = true

  startDate: any;
  endDate: any;
  disableCompany: boolean = false;
  LegalFormList: any = [];
  controlStatus: any = {
    ResidenceAddressZipCode: false,
    EmailAddress: false,
    Phone: false,
    WebsiteURL: false,
    BillingAddressZipCode: false
  };

  isNewContractCompany: boolean = false;
  templateRequiredField: any = []

  isThirdCase: boolean = false

  config: any = {
    fontNames: ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Helvetica', 'Roboto', 'sans-serif;', 'Times New Roman', 'Verdana'],
    height: 300,
    toolbar: [
      ['headline', ['style']],
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['picture','video', 'link']],
      ['table', ['table']]
    ]
  };

  contractCompanyList: Array<any> = [];
  @Input() id: string;
  @Input() customField: [];
  @Input() versionId: string;
  @Input() templateId: string;
  @Input() dataType: string;

  constructor(private formBuilder: FormBuilder,private el: ElementRef,private alertController: AlertController, private _apiService: ApiService, public _commonService: CommonService, private modalController: ModalController) { }


  ngOnInit() {
    this.contractComopanyForm = this.formBuilder.group({
      Name: ['', Validators.required],
      KvkNumber: [''],
      KvkResidenceNumber: [''],
      WebsiteURL: ['', Validators.pattern(this._commonService.URLPattern)],
      EstablishmentType: [''],
      ShowDashboard: [true],
      EmailAddress: ['', [Validators.required, Validators.email]],
      Fax: [''],
      Phone: ['', Validators.pattern(this._commonService.phonePattern)],
      SbiCode1: [''],
      SbiCode2: [''],
      
      BillingAddressCity: [''],
      BillingAddressCountry: [''],
      BillingAddressStreet: [''],
      BillingAddressStreetNumber: [''],
      BillingAddressZipCode: ['', Validators.pattern(this._commonService.postalCodePattern)],
      ResidenceAddressStreet: ['', Validators.required],
      ResidenceAddressStreetNumber: ['', Validators.required],
      ResidenceAddressCity: ['', Validators.required],
      ResidenceAddressZipCode: ['', [Validators.required, Validators.pattern(this._commonService.postalCodePattern)]],
      ResidenceAddressCountry: ['', Validators.required],
      Representative: [''],
      LegalFormId: [''],

    });
    this.contractCompanyId = this.id;
   
    this.disableCompany = false;
    this.locale = Number((localStorage.locale && localStorage.locale != '') ? localStorage.locale : 1043);
    if(this.locale == 1043) {
      this.config.lang = 'nl-NL';
    }
    this.tempLocale = this.locale;
    if (this.contractCompanyId == '') {
      this.contractCompanyId = null;
    }
    if (this.contractCompanyId) {
      this.templateRequiredField = this.customField;
      this.isNewContractCompany = false
      this.getContractCompanyDetails();
    }else{
      this.templateRequiredField = []
      this.isNewContractCompany = true
      this.getLocales();
      this.getLegalForm();
      this.showPage = true;
      this.contractCompany =
        {
          ContractCompanyId: this.contractCompanyId,
          ShowDashboard: "1",
          KvkNumber: '',
          KvkResidenceNumber: '',
          EmailAddress: '',
          Fax: '',
          EstablishmentType: '',
          Phone: '',
          WebsiteURL: '',
          SbiCode1: '',
          SbiCode2: '',
          BillingAddressCity: '',
          BillingAddressCountry: '',
          BillingAddressStreet: '',
          BillingAddressStreetNumber: '',
          BillingAddressZipCode: '',
          ResidenceAddressStreet: '',
          ResidenceAddressStreetNumber: '',
          ResidenceAddressCity: '',
          ResidenceAddressZipCode: '',
          ResidenceAddressCountry: '',
          Representative: '',
          LegalFormId: '',
          Type:'',
          Translations: [
            {
              Title: "",
              Description: "",
              LocaleId: this.locale,
            }
          ]
        }
    } 
  }

  async getLocales() {
    try {
      if (localStorage.domainLocales) {
        this.contractCompanyLocale.AllLocales = JSON.parse(localStorage.domainLocales);
      } else {
        let localeData = await this._apiService.getDomainLocales('', 1, 0);
        this.contractCompanyLocale.AllLocales = localeData.body;
        localStorage.domainLocales = JSON.stringify(localeData.body);
      }
    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async getContractCompanyDetails() {
    try {
      this._commonService.presentLoading();
      let res = await this._apiService.getContractCompanyDetailWizard(this.contractCompanyId);
      this.contractCompany = res.body;
      this.tempContractCompany = res.body;
      await this.getLocales();
      await this.getLegalForm();
      this.checkTemplateField();
      this._commonService.log(this.contractCompany, this._commonService.LOG);

       this.contractComopanyForm.patchValue({
        BillingAddressCity: this.contractCompany.BillingAddressCity,
        BillingAddressCountry: this.contractCompany.BillingAddressCountry,
        BillingAddressStreet: this.contractCompany.BillingAddressStreet,
        BillingAddressStreetNumber: this.contractCompany.BillingAddressStreetNumber,
        BillingAddressZipCode: this.contractCompany.BillingAddressZipCode,
        // EmailAddress: this.contractCompany.EmailAddress,
        ResidenceAddressStreet: this.contractCompany.ResidenceAddressStreet,
        ResidenceAddressStreetNumber: this.contractCompany.ResidenceAddressStreetNumber,
        ResidenceAddressCity: this.contractCompany.ResidenceAddressCity,
        ResidenceAddressZipCode: this.contractCompany.ResidenceAddressZipCode,
        ResidenceAddressCountry: this.contractCompany.ResidenceAddressCountry,
        KvkNumber: this.contractCompany.KvkNumber,
        KvkResidenceNumber: this.contractCompany.KvkResidenceNumber,
        EmailAddress: this.contractCompany.EmailAddress,
        Fax: this.contractCompany.Fax,
        ShowDashboard: this.contractCompany.ShowDashboard,
        Name: this.contractCompany.Name,
        EstablishmentType: this.contractCompany.EstablishmentType,
        WebsiteURL: this.contractCompany.WebsiteURL,
        Phone: this.contractCompany.Phone,
        SbiCode1: this.contractCompany.SbiCode1,
        SbiCode2: this.contractCompany.SbiCode2,
        Representative: this.contractCompany.Representative,
        LegalFormId: this.contractCompany.LegalFormId,
        });

      this.contractComopanyForm.updateValueAndValidity();
      // this.checkTemplateField()
      

      this.showPage = true;
      this._commonService.dismiss();
    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR)
    }
  }


  checkTemplateField(){
    if(this.templateRequiredField.length > 0){
      this.templateRequiredField.forEach(field => {
        Object.keys(this.contractComopanyForm.controls).forEach(key => {
            if(key.toLowerCase() == field){
              if(field == 'websiteurl'){
                this.contractComopanyForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.URLPattern)])
              }else if(field == 'emailaddress'){
                this.contractComopanyForm.controls[key].setValidators([Validators.required, Validators.email])
              }else if(field == 'phone'){
                this.contractComopanyForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.phonePattern)])
              }else if(field == 'billingaddresszipcode'){
                this.contractComopanyForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.postalCodePattern)])
              }else if(field == 'residenceaddresszipcode'){
                this.contractComopanyForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.postalCodePattern)])
              }else{
                this.contractComopanyForm.controls[key].setValidators(Validators.required)
              }
            
              this.contractComopanyForm.controls[key].setErrors({'required': true})
              this.contractComopanyForm.controls[key].markAsTouched();
              this.contractComopanyForm.updateValueAndValidity()
              this.controlStatus.ResidenceAddressZipCode = true
              this.controlStatus.EmailAddress = true
              this.controlStatus.Phone = true
              this.controlStatus.WebsiteURL = true
              this.controlStatus.BillingAddressZipCode = true
             
            }
        });
       
      });
     
    }
  }

  onBlurMethod(controlName){
    
    if(controlName == 'ResidenceAddressZipCode'){
      this.controlStatus.ResidenceAddressZipCode = true
    }
    if(controlName == 'EmailAddress'){
     this.controlStatus.EmailAddress = true
   }
   if(controlName == 'Phone'){
    this.controlStatus.Phone = true
  }
  if(controlName == 'WebsiteURL'){
    this.controlStatus.WebsiteURL = true
  }
  if(controlName == 'BillingAddressZipCode'){
    this.controlStatus.BillingAddressZipCode = true
  }
   }
 
   onFocusMethod(controlName){
     if(controlName == 'ResidenceAddressZipCode'){
       this.controlStatus.ResidenceAddressZipCode = false
     }
     if(controlName == 'EmailAddress'){
       this.controlStatus.EmailAddress = false
     }
     if(controlName == 'Phone'){
      this.controlStatus.Phone = false
    }
    if(controlName == 'WebsiteURL'){
      this.controlStatus.WebsiteURL = false
    }
    if(controlName == 'BillingAddressZipCode'){
      this.controlStatus.BillingAddressZipCode = false
    }
   }


  get f() { return this.contractComopanyForm.controls; }



  async onSubmit() {
    this.submitted = true;
    if(this.contractComopanyForm.controls['Name'].valid &&  this.contractComopanyForm.controls['EmailAddress'].valid && this.contractComopanyForm.controls['ResidenceAddressStreet'].valid && this.contractComopanyForm.controls['ResidenceAddressStreetNumber'].valid && this.contractComopanyForm.controls['ResidenceAddressCity'].valid && this.contractComopanyForm.controls['ResidenceAddressZipCode'].valid && this.contractComopanyForm.controls['ResidenceAddressCountry'].valid){
      this.formValidate = true
    }else{
      this.formValidate = false
    }

    // stop here if form is invalid
    if (this.contractComopanyForm.invalid && !this.formValidate) {
      this._commonService.scrollToError();
      this.controlStatus.ResidenceAddressZipCode = true
      this.controlStatus.EmailAddress = true
      this.controlStatus.Phone = true
      this.controlStatus.BillingAddressZipCode = true
      this.controlStatus.WebsiteURL = true
      return;
    }else if(this.contractComopanyForm.invalid && this.formValidate && !this.isThirdCase ){
        if((this.contractComopanyForm.controls['WebsiteURL'].errors && this.contractComopanyForm.controls['WebsiteURL'].errors.pattern)
          || (this.contractComopanyForm.controls['Phone'].errors && this.contractComopanyForm.controls['Phone'].errors.pattern)
          || (this.contractComopanyForm.controls['BillingAddressZipCode'].errors && this.contractComopanyForm.controls['BillingAddressZipCode'].errors.pattern)
          || (this.contractComopanyForm.controls['EmailAddress'].errors && this.contractComopanyForm.controls['EmailAddress'].errors.pattern)
          || (this.contractComopanyForm.controls['ResidenceAddressZipCode'].errors && this.contractComopanyForm.controls['ResidenceAddressZipCode'].errors.pattern)
          ){
          this.patternError = true
        }else{
          this.patternError = false
        }
      if(!this.patternError){
        this.openAlert()
      }
    }else{
      this.saveDetail()
  }
  }

  async saveDetail(){
    this._commonService.presentLoading();
    try {
      this.contractCompany.Name = this.contractComopanyForm.value.Name;

      this.contractCompany.KvkResidenceNumber = this.contractComopanyForm.value.KvkResidenceNumber;
      this.contractCompany.KvkNumber = this.contractComopanyForm.value.KvkNumber;
      this.contractCompany.EmailAddress = this.contractComopanyForm.value.EmailAddress.trim();
      this.contractCompany.Phone = this.contractComopanyForm.value.Phone;
      this.contractCompany.SbiCode1 = this.contractComopanyForm.value.SbiCode1;
      this.contractCompany.SbiCode2 = this.contractComopanyForm.value.SbiCode2;
      this.contractCompany.ShowDashboard = this.contractComopanyForm.value.ShowDashboard;
      this.contractCompany.EstablishmentType = this.contractComopanyForm.value.EstablishmentType;
      this.contractCompany.WebsiteURL = this.contractComopanyForm.value.WebsiteURL;
      this.contractCompany.ContractCompanyId = this.contractCompanyId ? this.contractCompanyId: null;

      this.contractCompany.BillingAddressCountry = this.contractComopanyForm.value.BillingAddressCountry;
      this.contractCompany.BillingAddressStreet = this.contractComopanyForm.value.BillingAddressStreet;
      this.contractCompany.BillingAddressStreetNumber = this.contractComopanyForm.value.BillingAddressStreetNumber;
      this.contractCompany.BillingAddressCity = this.contractComopanyForm.value.BillingAddressCity;
      this.contractCompany.BillingAddressZipCode = this.contractComopanyForm.value.BillingAddressZipCode;
      this.contractCompany.ResidenceAddressStreet = this.contractComopanyForm.value.ResidenceAddressStreet;
      this.contractCompany.ResidenceAddressStreetNumber = this.contractComopanyForm.value.ResidenceAddressStreetNumber;
      this.contractCompany.ResidenceAddressCity = this.contractComopanyForm.value.ResidenceAddressCity;
      this.contractCompany.ResidenceAddressZipCode = this.contractComopanyForm.value.ResidenceAddressZipCode;
      this.contractCompany.ResidenceAddressCountry = this.contractComopanyForm.value.ResidenceAddressCountry;
      this.contractCompany.Representative = this.contractComopanyForm.value.Representative;
      this.contractCompany.LegalFormId = this.contractComopanyForm.value.LegalFormId;
      let res = null;
      
     if(this.contractCompany.Type=='company'){
        this.dataType = 'companies';
        delete this.contractCompany.ContractCompanyId;
        this.contractCompany.CompanyId = this.contractCompanyId ? this.contractCompanyId: null;
        res = await this._apiService.postCompany(this.contractCompany);
     }else{
      this.dataType = 'contractcompanies';
        res = await this._apiService.postContractCompany(this.contractCompany);
     }

      // let res = await this._apiService.postContractCompany(this.contractCompany);
     
      if(res){
        let response = await this._apiService.checkCustomDataType(this.templateId, this.versionId, this.dataType, res.id)
        if (res.id  && response.body.fields.length == 0) {
          this.contractCompanyId = res.id;
          await this.getContractCompanies();
          this.isThirdCase = false
          this.closeModal(false);
        }else{
          this.templateRequiredField = response.body.fields
          this.contractCompanyId = res.id;
          await this.getContractCompanies();
          await this.checkTemplateField();
          if(!this.isThirdCase){
            this.openAlert()
          }
        }
      }
      this._commonService.dismiss();
      this._commonService.showToast(this._commonService.getLocalTranslation('label-recordSaved'));
      this._commonService.log(this.contractCompany, this._commonService.LOG);
      this.submitted = false;
      // save to localstorage
      //this._commonService.getcontractCompany();
    } catch (error) {
      if(error.status >= 500 && error.status < 600){
        
       this.contractComopanyForm.patchValue({
        BillingAddressCity: this.tempContractCompany.BillingAddressCity,
        BillingAddressCountry: this.tempContractCompany.BillingAddressCountry,
        BillingAddressStreet: this.tempContractCompany.BillingAddressStreet,
        BillingAddressStreetNumber: this.tempContractCompany.BillingAddressStreetNumber,
        BillingAddressZipCode: this.tempContractCompany.BillingAddressZipCode,
        // EmailAddress: this.contractCompany.EmailAddress,
        ResidenceAddressStreet: this.tempContractCompany.ResidenceAddressStreet,
        ResidenceAddressStreetNumber: this.tempContractCompany.ResidenceAddressStreetNumber,
        ResidenceAddressCity: this.tempContractCompany.ResidenceAddressCity,
        ResidenceAddressZipCode: this.tempContractCompany.ResidenceAddressZipCode,
        ResidenceAddressCountry: this.tempContractCompany.ResidenceAddressCountry,
        KvkNumber: this.tempContractCompany.KvkNumber,
        KvkResidenceNumber: this.tempContractCompany.KvkResidenceNumber,
        EmailAddress: this.tempContractCompany.EmailAddress,
        Fax: this.tempContractCompany.Fax,
        ShowDashboard: this.tempContractCompany.ShowDashboard,
        Name: this.tempContractCompany.Name,
        EstablishmentType: this.tempContractCompany.EstablishmentType,
        WebsiteURL: this.tempContractCompany.WebsiteURL,
        Phone: this.tempContractCompany.Phone,
        SbiCode1: this.tempContractCompany.SbiCode1,
        SbiCode2: this.tempContractCompany.SbiCode2,
        Representative: this.tempContractCompany.Representative,
        LegalFormId: this.tempContractCompany.LegalFormId,
        });
      }
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async openAlert(){

    setTimeout( async() => {
      let firstInvalidControl;
      firstInvalidControl = this.el.nativeElement.querySelector(
        "form .is-invalid"
      );
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });

      setTimeout( async() => {
        
        const alert = await this.alertController.create({
          header: '',
          message: this._commonService.getLocalTranslation('label-templateValidationError'),
          buttons: [
            {
              text: this._commonService.getLocalTranslation('label-ok'),
              handler: async () => {
                this.isThirdCase = true
                  await this.saveDetail();
                  this.closeModal(false);
                  
    
              }
            },
            {
              text: this._commonService.getLocalTranslation('label-cancel'),
              role: 'cancel',
              handler: () => {
              }
            }
          
    
          ]
        });
        await alert.present();
      }, 200);
    }, 300);
  }

  async getLegalForm() {
    try {
      if (localStorage.LegalFormList) {
        this.LegalFormList  = JSON.parse(localStorage.LegalFormList);
      } else {
        let legalForm = await this._apiService.getLegalForm();
        this.LegalFormList = legalForm.body;
        localStorage.setItem('LegalFormList', JSON.stringify(legalForm.body));
      }            
       
    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }
  
  onChangeBreadcrumb() {
      this.contractCompany.Name = this.contractComopanyForm.value.Name;
  }

  async closeModal(closed) {
    let obj = {};
    if(this.contractCompanyId) {
      obj['id'] = this.contractCompanyId;
    } 
    if(this.contractCompanyList && this.contractCompanyList.length > 0) {
      obj['list'] = this.contractCompanyList;
    }
    obj['closed'] = closed;
    this.modalController.dismiss(obj);
  }

  async getContractCompanies() {
    try {
      this._commonService.presentLoading();
      let res = await this._apiService.getContractCompanyWizard();
      this.contractCompanyList = res.body;
      this._commonService.dismiss();
    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  copyAddress(){
    this.contractComopanyForm.patchValue({
      BillingAddressCity: this.contractComopanyForm.get('ResidenceAddressCity').value,
      BillingAddressCountry: this.contractComopanyForm.get('ResidenceAddressCountry').value,
      BillingAddressStreet: this.contractComopanyForm.get('ResidenceAddressStreet').value,
      BillingAddressStreetNumber: this.contractComopanyForm.get('ResidenceAddressStreetNumber').value,
      BillingAddressZipCode: this.contractComopanyForm.get('ResidenceAddressZipCode').value
      });
  }

}
