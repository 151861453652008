import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';

import {Injectable} from '@angular/core';

@Injectable()
export class DateTimeIntl extends OwlDateTimeIntl {
    /** A label for the cancel button */
    cancelBtnLabel = JSON.parse(localStorage.translations)['label-cancel'];

    /** A label for the set button */
    setBtnLabel = JSON.parse(localStorage.translations)['label-ok'];
};
