import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { FormsModule } from '@angular/forms';
import { HTTPInterceptor } from './helpers/http.interceptor';
import { AutofocusDirective } from './directives/autofocus.directive';
import { NoMarginDirective } from './directives/no-margin.directive';
import { TreeDragDropService } from './shared/components/tree/common/treedragdropservice';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgxPopperModule } from 'ngx-popper';
import { HeaderModule } from './shared/components/header/header.module';
import { ProfilePopoverComponent } from './shared/components/profile-popover/profile-popover.component';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { MatomoModule } from 'ngx-matomo';
import { SharedModule } from './shared/shared.module';
import { ConfigService } from './services/config.service';
import { environment } from 'src/environments/environment';


registerLocaleData(localeNL);

@NgModule({
  declarations: [AppComponent, AutofocusDirective, NoMarginDirective, ProfilePopoverComponent],
  entryComponents: [ProfilePopoverComponent],
  imports: [
    MatomoModule.forRoot({
      scriptUrl: environment.matomoScriptUrl,
      trackers: [
        // {
        //   trackerUrl: '',
        //   siteId: 1,
        // }
      ],
      routeTracking: {
        enable: true
      }
    }),
    BrowserModule,
    IonicModule.forRoot({ animated: true, innerHTMLTemplatesEnabled: true },),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSmartModalModule.forRoot(),
    FormsModule,
    TranslateModule.forRoot(),
    MatSidenavModule,
    MatMenuModule,
    NgxPopperModule.forRoot({ placement: 'auto', trigger: 'hover', appendTo: 'body', applyClass: 'tooltip' }),
    HeaderModule,
    SharedModule
  ],
  providers: [NgxSmartModalService, TreeDragDropService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(private configService: ConfigService) {
  //   this.loadConfig();
  // }

  // private loadConfig(): void {
  //   this.configService.getConfig().subscribe((configData) => {
  //     const matomoTracker = {
  //       trackerUrl: configData.urlAnalytics,
  //       siteId: 1,
  //     };
  //     console.log('matomoTracker:', matomoTracker);


  //     MatomoModule.forRoot({
  //       trackers: [matomoTracker],
  //     });
  //   });
  // }
}
