import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
  private regex: RegExp = /^[0-9]*([,][0-9]*)?$/;

  constructor(private _el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
        return;
    }

    let current: string = String(this._el.nativeElement.value || ''); // Convert to string
    let next: string = current.concat(event.key);

    if (next && !String(next).match(this.regex)) {
        event.preventDefault();
    }
}


}
