import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

import { CommonService } from './common.service';
import { MainService } from './main.service';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { TranslationService } from './translation.service';

declare function unInitializeChat():any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _httpClient: HttpClient,public _apiService: ApiService, public _translationService :TranslationService, public _commonService: CommonService, private router: Router, private alertController: AlertController, private ngZone: NgZone, private _mainService: MainService) { }

  // Check if allowedDomains includes the current logged in domainname
  isAuthorized(allowedDomains: string[]): boolean {
    if (allowedDomains == null || allowedDomains.length === 0) {
      return true;
    }
    if (!localStorage.authenticationKey) {
      this.router.navigate(['login']);
      this._commonService.isLoggedIn = false;
      return false;
    }
    return allowedDomains.includes(localStorage.domainName.toLowerCase());
  }
  
  changeDefaultPassword(params:any, apiName: any, authKey:any){
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'weboffice',
        'X-Security-AuthKey': authKey ? authKey : "",
      })
    };
    return this._httpClient.patch((this._mainService.url ? this._mainService.url : environment.url) + apiName + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), params, httpOptions).toPromise();
  }

  doVerifyPassCode(params: any, apiName: string, authKey:any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'weboffice',
        'X-Security-AuthKey': authKey ? authKey : "",
      }),
      observe: "response" as 'body'
    };
    return this._httpClient.post((this._mainService.url ? this._mainService.url : environment.url) + apiName + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), params, httpOptions).pipe(map((res: any) => {
      return res;
    }));
  }

doLogin(params: any, apiName: string): Observable < any > {
  let httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'X-LoginSource': 'weboffice'
    }),
    observe: "response" as 'body'
  };
  return this._httpClient.post((this._mainService.url ? this._mainService.url : environment.url) + apiName + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), params, httpOptions).pipe(map((res: any) => {
    return res;
  }));
}

  doDomainLogin(params: any, apiName: string, authKey: string): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'weboffice',
        'X-Security-AuthKey': authKey ? authKey : "",
      })
    };
    return this._httpClient.post((this._mainService.url ? this._mainService.url : environment.url) + apiName + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), params, httpOptions).pipe(map((res: any) => {
      if (res) {
        localStorage.setItem('authenticationKey', res.AuthenticationKey);
      }
      return res;
    }));
  }

  async logout() {
    const alert = await this.alertController.create({
      header: this._commonService.getLocalTranslation('label-logOut'),
      message: this._commonService.getLocalTranslation('label-closeAndLogout'),
      buttons: [
        {
          text: this._commonService.getLocalTranslation('label-ok'),
          handler: () => {

            //close trengo chat
            unInitializeChat();

            //  logout the user
            this._commonService.presentLoading();
            this.doLogout(localStorage.authenticationKey).subscribe(
              (res) => {
                this._commonService.dismiss();
               
                this._commonService.redirectURL = null;
                 this._commonService.showDashboardTiles = true;
                 this._commonService.isAdmin = false;
                 this._commonService.isCustomerAdmin = false;
                 this._commonService.isCustomerUser = false;
                 this._commonService.isJuristUser = false
                 this._commonService.isSupportUser = false
                this._commonService.clearData();   
                this._commonService.isLoggedIn = false
                this.ngZone.run(() => this.router.navigate(['/login'], { replaceUrl: true }));
             
              },
              (err) => {
                this._commonService.dismiss();
              
                this._commonService.redirectURL = null;
                this._commonService.showDashboardTiles = true;
                this._commonService.isAdmin = false;
                this._commonService.isCustomerAdmin = false;
                this._commonService.isCustomerUser = false;
                this._commonService.isJuristUser = false
                this._commonService.isSupportUser = false
                this._commonService.clearData();
                this._commonService.isLoggedIn = false
                this.ngZone.run(() => this.router.navigate(['/login'], { replaceUrl: true }));
              }
            )
          }
        },
        {
          text: this._commonService.getLocalTranslation('label-cancel'),
          role: 'cancel',
          handler: () => {
          }
        }
      

      ]
    });
    await alert.present();
  }

  doLogout(authKey): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : "",
      'X-LoginSource': 'weboffice'
    })
    return this._httpClient.put((this._mainService.url ? this._mainService.url : environment.url) + 'Logout?localeCode=' + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), {}, { headers: headers }).pipe(map((res: any) => {
      return res;
    }));
  }




  

}
