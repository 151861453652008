import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import xml2js from 'xml2js';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  faqList: any = [];
  showPage: boolean = false
  faqXMLFile: any;
  disabledButton: boolean = false;

  @Output() performAction = new EventEmitter<any>(true);


  constructor(public _commonService: CommonService, private router: Router,private route: ActivatedRoute, private _apiService: ApiService, private httpService: HttpClient) {
    if(this._commonService.isLoggedIn){
      if(localStorage.roleCode == 'UserRole_Administrator'){
        this.disabledButton = true
      }else{
        this.disabledButton = false
      }
      if( localStorage.faqList){
        this.faqList = JSON.parse(localStorage.faqList)
      }else{
        this.faqList = []
      }
    }else{
      this.loadXML()
    }

     }

  ngOnInit() {

  }

  loadXML() {
    const requestOptions: Object = {
      observe: "body",
      responseType: "text"
    };
    this.httpService.get<any>("https://www.rechtdirect.nl/?feed=faqfeed", requestOptions)
      .subscribe((data) => {
        this.parseXML(data)
          .then((data) => {
            let d: any = data
            if(d.length > 3){
              this.faqXMLFile = d.slice(0, 3)
              this.faqXMLFile = [...this.faqXMLFile]
            }else{
              this.faqXMLFile = data;
            }
          });
      });
  }

  parseXML(data) {
    return new Promise(resolve => {
      var k: string | number,
        arr = [],
        parser = new xml2js.Parser(
          {
            trim: true,
            explicitArray: true
          });
      parser.parseString(data, function (err, result) {
        var obj = result.rss.channel[0];
        for (k in obj.item) {
          var item = obj.item[k];
          arr.push({
            Question: item.title[0],
            Link: item.link[0]
          });
        }
        resolve(arr);
      });
    });
  }


  showToast(){
    this._commonService.showToast('Het document wordt opgeslagen');
  }

  gotoFaq(){
    if(this._commonService.isLoggedIn && localStorage.domainName != 'MASTER'){

      this.router.navigate(['frequentlyaskedquestions'])
    }
  }

  gotoFaqWebsite(){
    window.open('https://www.rechtdirect.nl/klantenservice/', '_blank');
  }

  gotoDetail(faq){
   if(this.route.snapshot['_routerState'].url != '/frequentlyaskedquestions'){

     if(this._commonService.isLoggedIn && localStorage.domainName != 'MASTER'){
     const navigationExtras: NavigationExtras = {
       state: { faq }
     };
     this.router.navigate(['frequentlyaskedquestions'], navigationExtras);
   }
   }else{
    this.performAction.emit(faq);
    //this._commonService.performFaqAction.emit(faq)
   }
}

gotoNewDetailWindow(faq){
  window.open(faq.Link, '_blank');
}



}
