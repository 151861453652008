import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FileSizePipe implements PipeTransform {

  // To determine the size and extension  of file according to value passed and default extension is 'MB'
  transform(size: number, extension: string = 'MB') {
    if (!size || size == 0) return 0 + ' bytes';
    if ( (size / 1024) < 1) return size.toFixed(1) + ' bytes';
    if(size/(1024 * 1024) < 1) return (size / (1024)).toFixed(1) + ' KB';
    return (size / (1024 * 1024)).toFixed(1) + ' ' + extension;
  }

}