import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[euroCurrency]'
})
export class EuroCurrencyDirective {

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
  private regex: RegExp = /^[0-9]*([,][0-9]{0,2})?$/;

  constructor(private _el: ElementRef) { }

  @HostListener('keydown', ['$event']) 
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
        event.preventDefault();
    }
  }
}

