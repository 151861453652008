import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss'],
})
export class PdfviewerComponent implements OnInit {

  constructor(public _commonService: CommonService) { 
    
  }

  ngOnInit() {}

  closeModal(){
    if(this._commonService.pdfModal){
      this._commonService.pdfModal.dismiss()
    }
  }

  pageRendered(event){
    console.log(event)
  }


}
