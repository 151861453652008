import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss'],
})
export class CircularProgressComponent implements OnInit, OnChanges {
  @Input() size: string = '';
  @Input() total: number;
  @Input() completed: number;
  value: any;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.value = (this.completed/this.total)*360;
  }
}
