import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginAccessGuard } from './guards/loginaccess.guard';
import { BlockresetpageGuard } from './guards/blockresetpage.guard';
import { BlockotpverificationGuard } from './guards/blockotpverification.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

  { path: 'login', loadChildren: () => import( './auth/login/login.module').then(m => m.LoginPageModule), canActivate: [LoginAccessGuard] },
  { path: 'dashboard', loadChildren: () => import( './dashboard/dashboard.module').then(m => m.DashboardPageModule)},
  { path: 'dashboard/:id', loadChildren: () => import( './dashboard/dashboard.module').then(m => m.DashboardPageModule)},
  { path: 'maintenance/companies', loadChildren: () => import( './maintenance/companies/companies.module').then(m => m.CompaniesPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/companies/:id', loadChildren: () => import( './maintenance/companies/company/company.module').then(m => m.CompanyPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/domains', loadChildren: () => import( './maintenance/domains/domains.module').then(m => m.DomainsPageModule), canActivate: [AuthGuard]
   },
  { path: 'maintenance/domains/:id', loadChildren: () => import( './maintenance/domains/domain/domain.module').then(m => m.DomainPageModule), canActivate: [AuthGuard]},

  { path: 'profile', loadChildren: () => import( './profile/profile.module').then(m => m.ProfilePageModule), canActivate: [AuthGuard] },
  { path: 'maintenance', loadChildren: () => import( './maintenance/maintenance.module').then(m => m.MaintenancePageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/users', loadChildren: () => import( './maintenance/users/users.module').then(m => m.UsersPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/users/:id', loadChildren: () => import( './maintenance/users/user/user.module').then(m => m.UserPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/locales', loadChildren: () => import( './maintenance/locales/locales.module').then(m => m.LocalesPageModule), canActivate: [AuthGuard], data: {
      allowedRoles: [],
      allowedDomains: ['master'],
    } },
  { path: 'maintenance/locales/:id', loadChildren: () => import( './maintenance/locales/locale/locale.module').then(m => m.LocalePageModule), canActivate: [AuthGuard], data: {
      allowedRoles: [],
      allowedDomains: ['master'],
    } },
  { path: 'maintenance/notificationtemplates', loadChildren: () => import( './maintenance/notification-templates/notification-templates.module').then(m => m.NotificationTemplatesPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/notificationtemplates/:id', loadChildren: () => import( './maintenance/notification-templates/notification-template/notification-template.module').then(m => m.NotificationTemplatePageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/permissions', loadChildren: () => import( './maintenance/permissions/permissions.module').then(m => m.PermissionsPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/settings', loadChildren: () => import( './maintenance/settings/settings.module').then(m => m.SettingsPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/subscriptionsettings', loadChildren: () => import('./maintenance/subscription/subscription.module').then(m=>m.SubscriptionPageModule),canActivate: [AuthGuard]  },
  { path: 'maintenance/subscriptionsettings/:id', loadChildren: () => import('./maintenance/subscription/subscriptiondetail/subscriptiondetail.module').then(m=>m.SubscriptiondetailPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/messages', loadChildren: () => import( './maintenance/messages/messages.module').then(m => m.MessagesPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/messages/:id', loadChildren: () => import( './maintenance/messages/message/message.module').then(m => m.MessagePageModule), canActivate: [AuthGuard]  },
  { path: 'maintenance/systemtranslations', loadChildren: () => import( './maintenance/systemtranslations/systemtranslations.module').then(m => m.SystemtranslationsPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/systemtranslations/:id', loadChildren: () => import( './maintenance/systemtranslations/systemtranslation/systemtranslation.module').then(m => m.SystemtranslationPageModule), canActivate: [AuthGuard] },
  { path: 'messages', loadChildren: () => import( './messages/messages.module').then(m => m.MessagesPageModule), canActivate: [AuthGuard] },
  { path: 'forgot-password', loadChildren: () => import( './auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'forgot-password/:id', loadChildren: () => import( './auth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) },
  { path: 'registration', loadChildren: () => import( './auth/registration/registration.module').then(m=> m.RegistrationPageModule) },
  { path: 'loginverification', loadChildren: () => import( './auth/otpverification/otpverification.module').then(m => m.OtpverificationPageModule) },
  { path: 'resetdefaultpassword', loadChildren: () => import( './auth/reset-defaultpassword/reset-defaultpassword.module').then(m => m.ResetDefaultpasswordPageModule), canActivate: [BlockresetpageGuard]},
  { path: 'maintenance/quickscan', loadChildren:() => import('./maintenance/m-quickscan/quickscanlist/quickscanlist.module').then(m => m.QuickscanlistPageModule) , canActivate: [AuthGuard]},
  { path: 'maintenance/quickscan/:id', loadChildren:() => import('./maintenance/m-quickscan/quickscanlist/quickscandetail/quickscandetail.module').then(m => m.QuickscandetailPageModule) , canActivate: [AuthGuard]},
  { path: 'maintenance/suggestions', loadChildren: () => import( './maintenance/suggestions/suggestions.module').then(m => m.SuggestionsPageModule) , canActivate: [AuthGuard]},
  { path: 'maintenance/suggestions/:id', loadChildren: () => import( './maintenance/suggestions/suggestion/suggestion.module').then(m => m.SuggestionPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/helptexts', loadChildren: () => import('./maintenance/helptexts/helptexts.module').then(m => m.HelptextsPageModule), canActivate: [AuthGuard]},
  { path: 'maintenance/helptexts/:id', loadChildren: () => import ( './maintenance/helptexts/helptext/helptext.module').then(m => m.HelptextPageModule), canActivate: [AuthGuard] },
  { path: 'frequentlyaskedquestions', loadChildren:() => import( './faqs/faqs.module').then(m => m.FaqsPageModule) , canActivate: [AuthGuard]},
  { path: 'frequentlyaskedquestions/:id/faqId/:faqId', loadChildren:() => import( './faqs/faqs.module').then(m => m.FaqsPageModule)},
  { path: 'Users/Invitation/:id', loadChildren: () => import( './inviteuser/inviteuser.module').then(m => m.InviteuserPageModule) },
  { path: 'quickscan', loadChildren: () => import( './quickscan/quickscan.module').then(m=> m.QuickscanPageModule) , canActivate: [AuthGuard]},
  { path: 'quickscan/wizard', loadChildren: () => import( './quickscan/quickscan-fill/quickscan-fill.module').then(m => m.QuickscanFillPageModule), canActivate: [AuthGuard]},
  { path: 'quickscan/result', loadChildren: () => import('./quickscan/quickscan-fill/quickscanresult/quickscanresult.module').then(m=>m.QuickscanresultPageModule) , canActivate: [AuthGuard]},
  { path: 'maintenance/documentgenerator', loadChildren:() => import('./maintenance/m-documentgenerator/m-documentgenerator.module').then(m => m.MDocumentgeneratorPageModule) , canActivate: [AuthGuard]},
  { path: 'maintenance/documentgenerator/:id', loadChildren:() => import('./maintenance/m-documentgenerator/templatedetail/templatedetail.module').then(m =>m.TemplatedetailPageModule) , canActivate: [AuthGuard]},
  { path: 'maintenance/documentgenerator/:id/upload', loadChildren:() => import('./maintenance/m-documentgenerator/templateupload/templateupload.module').then(m =>m.TemplateuploadPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/documentgenerator/:id/version/:versionid', loadChildren:() => import('./maintenance/m-documentgenerator/versiondetail/versiondetail.module').then(m =>m.VersiondetailPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/informationblock', loadChildren:() => import('./maintenance/informationblock/informationblock.module').then(m => m.InformationblockPageModule) , canActivate: [AuthGuard]},
  { path: 'maintenance/informationblock/:id', loadChildren:() => import('./maintenance/informationblock/informationblockdetail/informationblockdetail.module').then(m => m.InformationblockdetailPageModule), canActivate: [AuthGuard]},
  { path: 'maintenance/knowledgecenter', loadChildren:() => import('./maintenance/knowledgecenter/knowledgecenter.module').then(m => m.KnowledgecenterPageModule) , canActivate: [AuthGuard]},
  { path: 'maintenance/knowledgecenter/:id', loadChildren:() => import('./maintenance/knowledgecenter/knowledgecenterdetail/knowledgecenterdetail.module').then(m => m.KnowledgecenterdetailPageModule), canActivate: [AuthGuard]},
  { path: 'maintenance/quickscan/:id/tree', loadChildren: () => import( './maintenance/m-quickscan/m-quickscan.module').then(m => m.MQuickscanPageModule), canActivate: [AuthGuard] },
  { path: 'verifyemail/:id', loadChildren: () => import( './verifyemail/verifyemail.module').then(m => m.VerifyemailPageModule) },
  { path: 'setup2fa/:id/:username', loadChildren: () => import('./auth/otpverification/intermediate2-fa/intermediate2-fa.module').then(m => m.Intermediate2FAPageModule) },
  { path: 'maintenance/contractcompanies', loadChildren: () => import( './maintenance/contract-company/contract-company.module').then(m=> m.ContractCompanyPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/contractcompanies/:id', loadChildren: () => import( './maintenance/contract-company/contract-company-detail/contract-company-detail.module').then(m => m.ContractCompanyDetailPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/frequentlyaskedquestions', loadChildren: () => import( './maintenance/faqs/faqs.module').then(m => m.FaqsPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/frequentlyaskedquestions/:id', loadChildren: () => import( './maintenance/faqs/faqdetail/faqdetail.module').then(m => m.FaqdetailPageModule), canActivate: [AuthGuard] },
  { path: 'categories', loadChildren: () => import('./maintenance/faqs/categories/categories.module').then(m => m.CategoriesPageModule), canActivate: [AuthGuard] },
  { path: 'notifications', loadChildren: () => import( './notifications/notifications.module').then(m => m.NotificationsPageModule), canActivate: [AuthGuard] },
  { path: 'notifications/:id', loadChildren: () => import( './notifications/notification/notification.module').then(m => m.NotificationPageModule), canActivate: [AuthGuard] },
  { path: 'profile/:id', loadChildren: () => import( './inviteprofile/inviteprofile.module').then( m=> m.InviteprofilePageModule) },
  { path: 'maintenance/contractpersons', loadChildren: () => import( './maintenance/contract-persons/contract-persons.module').then(m => m.ContractPersonsPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/contractpersons/:id', loadChildren: () => import( './maintenance/contract-persons/contract-person/contract-person.module').then(m => m.ContractPersonPageModule) },
  { path: 'maintenance/helppremiumconfig',loadChildren: () => import( './maintenance/help-premium-config/help-premium-config.module').then(m => m.HelpPremiumConfigPageModule), canActivate: [AuthGuard] },
  { path: 'documentgenerator', loadChildren: () => import('./documentgenerator/documentgenerator.module').then(m=> m.DocumentgeneratorPageModule), canActivate: [AuthGuard] },
  { path: 'documentgenerator/wizard/:templateId', loadChildren: () => import('./documentgenerator/documentgenerator-wizard/documentgenerator-wizard.module').then(m=> m.DocumentgeneratorWizardPageModule), canActivate: [AuthGuard] },
  { path: 'documentgenerator/wizard/:templateId/:versionId', loadChildren: () => import('./documentgenerator/documentgenerator-wizard/documentgenerator-wizard.module').then(m=> m.DocumentgeneratorWizardPageModule), canActivate: [AuthGuard] },
  { path: 'documentlibrary', loadChildren: () => import('./documentlibrary/documentlibrary.module').then(m=> m.DocumentlibraryPageModule), canActivate: [AuthGuard] },
  { path: 'documentlibrary/:sectionName/:documentId/:domainId', loadChildren: () => import('./documentlibrary/documentlibrarydetails/documentlibrarydetails.module').then(m=> m.DocumentlibrarydetailPageModule), canActivate: [AuthGuard]  },
  { path: 'documentlibrary/:sectionName/:documentId', loadChildren: () => import('./documentlibrary/documentlibrarydetails/documentlibrarydetails.module').then(m=> m.DocumentlibrarydetailPageModule), canActivate: [AuthGuard]  },

  { path: 'maintenance/standarddocuments',  loadChildren: () => import('./maintenance/standarddocuments/standarddocuments.module').then(m=> m.StandarddocumentsPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/standarddocuments/:id', loadChildren: () => import('./maintenance/standarddocuments/standarddocumentdetails/standarddocumentdetails.module').then(m=> m.StandarddocumentdetailsPageModule), canActivate: [AuthGuard] },
  
  { path: 'sso_landing', loadChildren: () => import('./sso/sso-landing/sso-landing.module').then(m=> m.SsoLandingPageModule)},
  { path: 'sso_callback', loadChildren: () => import('./sso/sso-callback/sso-callback.module').then(m=> m.SsoCallbackPageModule)},
  { path: 'maintenance/emailtemplates', loadChildren: () => import( './maintenance/email-templates/email-templates.module').then(m => m.EmailTemplatesPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance/emailtemplates/:id', loadChildren: () => import( './maintenance/email-templates/email-template/email-template.module').then(m => m.EmailTemplatePageModule), canActivate: [AuthGuard] },
  { path: 'knowledgeitems/:id', loadChildren: () => import('./knowledgeitems/knowledgeitems.module').then( m => m.KnowledgeitemsPageModule), canActivate: [AuthGuard]},
  { path: 'maintenance/domains/:domainid/company/:companyid',loadChildren: () => import('./maintenance/domaincompany/domaincompany.module').then( m => m.DomaincompanyPageModule), canActivate: [AuthGuard]},
  {
    path: 'maintenance/questionoftheweek',
    loadChildren: () => import('./maintenance/question-of-the-week/question-of-the-week.module').then( m => m.QuestionOfTheWeekPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'maintenance/questionoftheweek/:id',
    loadChildren: () => import('./maintenance/question-of-the-week/question-of-the-week-detail/question-of-the-week-detail.module').then( m => m.QuestionOfTheWeekDetailPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'processingactivities',
    loadChildren: () => import('./processing-activities/processing-activities.module').then( m => m.ProcessingActivitiesPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'processingactivity/:id',
    loadChildren: () => import('./processing-activities/processing-activitydetail/processing-activitydetail.module').then( m => m.ProcessingActivitydetailPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'processingrecords/:id',
    loadChildren: () => import('./process-register-detail/process-register-detail.module').then( m => m.ProcessRegisterDetailPageModule), canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: 'dashboard' },
 





 





  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
