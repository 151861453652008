import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CommonService } from '../../../services/common.service';
import { ApiService } from '../../../services/api.service';
import { DatatableComponent } from '@siemens/ngx-datatable';
import { Subscription } from 'rxjs';
import { Page } from 'src/app/models/page.model';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
})
export class AddCompanyComponent implements OnInit {
  @ViewChild('table') table: DatatableComponent;
  @Input() id: string;
  @Input() customField: [];
  @Input() versionId: string;
  @Input() templateId: string;
  @Input() dataType: string;

  enableReadOnly: boolean = false;
  companyId: string;
  company: any = {};
  companies: any = {};
  tempCompanies: any = {}
  showPage: boolean = false;
  showObjectGroups: boolean = false;
  showUsers: boolean = false;
  preventClick: boolean = true;
  toggleSubscription: Subscription;
  controlStatus: any = {
    ResidenceAddressZipCode: false,
    EmailAddress: false,
    Phone: false,
    WebsiteURL: false,
    BillingAddressZipCode: false
  };


  locale: any;
  tempLocale: any;

  patternError: boolean = false

  isThirdCase: boolean = false

  companyForm: FormGroup;
  submitted = false;
  companyLogo: any = null;
  category: string;
  invoiceList: any = []
  columnsWidth: any = [];


  itemsSelected: any = [];
  formValidate: boolean = true

  page = new Page();
  isNewCompany: boolean = false;

  LegalFormList: any = [];

  companyList: Array<any> = [];
  templateRequiredField: any = []

  constructor(private formBuilder: FormBuilder,private el: ElementRef, private _apiService: ApiService, private alertController: AlertController, public _commonService: CommonService, private modalController: ModalController) {
    this.category = "details";
    this.page.size = 20;
    this.page.pageNumber = 0;
  }

  ngOnInit() {
    this.companyForm = this.formBuilder.group({
      Name: ['', Validators.required],
      KvkNumber: ['', Validators.required],
      KvkResidenceNumber: [''],
      WebsiteURL: ['', [Validators.pattern(this._commonService.URLPattern)]],
      EstablishmentType: [''],
      EmailAddress: ['', [Validators.required, Validators.email]],
      Phone: ['', [Validators.pattern(this._commonService.phonePattern)]],
      SbiCode1: [''],
      SbiCode2: [''],
      IsPrimaryCompany: [false],

      BillingAddressCity: [''],
      BillingAddressCountry: [''],
      BillingAddressStreet: [''],
      BillingAddressStreetNumber: [''],
      BillingAddressZipCode: ['', [Validators.pattern(this._commonService.postalCodePattern)]],
      ResidenceAddressStreet: ['', Validators.required],
      ResidenceAddressStreetNumber: ['', Validators.required],
      ResidenceAddressCity: ['', Validators.required],
      ResidenceAddressZipCode: ['', [Validators.required, Validators.pattern(this._commonService.postalCodePattern)]],
      ResidenceAddressCountry: ['', Validators.required],
      Representative: [''],
      LegalFormId: [''],
    });
    this.companyId = this.id;


    this.locale = Number((localStorage.locale && localStorage.locale != '') ? localStorage.locale : 1043);
    this.tempLocale = this.locale;
    if (this.companyId == '') {
      this.companyId = null;
    }
    if (this.companyId) {
      this.isNewCompany = false
      this.templateRequiredField = this.customField;
      this.getCompanyDetails();
    } else {
      this.templateRequiredField = []
      this.isNewCompany = true;
      this.getLocales();
      this.getLegalForm();
      this.showPage = true;
      this.companies =
      {
        CompanyId: this.companyId,
        KvkNumber: '',
        KvkResidenceNumber: '',
        EmailAddress: '',
        EstablishmentType: '',
        Phone: '',
        WebsiteURL: '',
        SbiCode1: '',
        SbiCode2: '',
        IsPrimaryCompany: false,
        BillingAddressCity: '',
        BillingAddressCountry: '',
        BillingAddressStreet: '',
        BillingAddressStreetNumber: '',
        BillingAddressZipCode: '',
        ResidenceAddressStreet: '',
        ResidenceAddressStreetNumber: '',
        ResidenceAddressCity: '',
        ResidenceAddressZipCode: '',
        ResidenceAddressCountry: '',
        Representative: '',
        LegalFormId: '',
        Type : '',
        Translations: [
          {
            Name: "",
            Description: "",
            LocaleId: this.locale,
          }
        ]
      }
    }
  }

  adjustColumnWidth() {
    if (this.table && window.innerWidth > 800) {

      let tableWidth = this.table._innerWidth
      this.columnsWidth.InoviceDate = (35 / 100) * tableWidth;
      this.columnsWidth.InoviceNumber =  (35 / 100) * tableWidth
      this.columnsWidth.Tax =  (30 / 100) * tableWidth

    }
    if (this.table && window.innerWidth < 800) {
      let tableWidth = this.table._innerWidth
      this.columnsWidth.InoviceDate = (35 / 100) * tableWidth;
      this.columnsWidth.InoviceNumber =  (35 / 100) * tableWidth
      this.columnsWidth.Tax =  (30 / 100) * tableWidth

    }
  }

  async getLocales() {
    try {
      if (localStorage.domainLocales) {
        this.company.AllLocales = JSON.parse(localStorage.domainLocales);
      } else {
        let localeData = await this._apiService.getDomainLocales('', 1, 0);
        this.company.AllLocales = localeData.body;
        localStorage.domainLocales = JSON.stringify(localeData.body);
      }
    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async getInvoiceList() {
    try {
      this._commonService.presentLoading()
        let invoiceData = await this._apiService.getInvoiceList(this.companyId, '', this.page.orderBy, this.page.orderDir, 1, this.page.size);
        this.page.totalElements = invoiceData.headers.get('X-Page-TotalCount');
        this.invoiceList = invoiceData.body;
      this._commonService.dismiss()

    } catch (error) {
      this._commonService.dismiss()
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async getCompanyDetails() {
    this.enableReadOnly = false;
    this._commonService.presentLoading();
    try {
      let res = await this._apiService.getCompanyWizard(this.companyId);
      this.companies = res.body;
      this.tempCompanies = res.body
      await this.getLocales();
      await this.getLegalForm();
      this.checkTemplateField();
      this._commonService.log(this.companies, this._commonService.LOG);

      //  if (this.companies.LogoName) {
      //    let ext = this.companies.LogoName.split('.').pop();
      //    if (this.companies.Logo) {
      //      this.companyLogo = "data:image/" + ext + ";base64," + this.companies.Logo;
      //    } else {
      //      this.companies.LogoName = '';
      //    }
      //  }
      if(this.companies.IsPrimaryCompany == '1') {
        this.enableReadOnly = true;
      }
      this.companies.IsPrimaryCompany = this.companies.IsPrimaryCompany == '1' ? true : false;
      this.companyForm.patchValue({
        BillingAddressCity: this.companies.BillingAddressCity,
        BillingAddressCountry: this.companies.BillingAddressCountry,
        BillingAddressStreet: this.companies.BillingAddressStreet,
        BillingAddressStreetNumber: this.companies.BillingAddressStreetNumber,
        BillingAddressZipCode: this.companies.BillingAddressZipCode,
        // EmailAddress: this.companies.EmailAddress,
        ResidenceAddressStreet: this.companies.ResidenceAddressStreet,
        ResidenceAddressStreetNumber: this.companies.ResidenceAddressStreetNumber,
        ResidenceAddressCity: this.companies.ResidenceAddressCity,
        ResidenceAddressZipCode: this.companies.ResidenceAddressZipCode,
        ResidenceAddressCountry: this.companies.ResidenceAddressCountry,
        KvkNumber: this.companies.KvkNumber,
        KvkResidenceNumber: this.companies.KvkResidenceNumber,
        EmailAddress: this.companies.EmailAddress,
        IsPrimaryCompany: Number(this.companies.IsPrimaryCompany == '1') ? true : false,
        Name: this.companies.Name,
        EstablishmentType: this.companies.EstablishmentType,
        WebsiteURL: this.companies.WebsiteURL,
        Phone: this.companies.Phone,
        SbiCode1: this.companies.SbiCode1,
        SbiCode2: this.companies.SbiCode2,
        Representative: this.companies.Representative,
        LegalFormId: this.companies.LegalFormId,
      });
      this.companyForm.updateValueAndValidity();
      // this.checkTemplateField()

      this.showPage = true;
      this._commonService.dismiss();
    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR)
    }
  }

  onBlurMethod(controlName){

    if(controlName == 'ResidenceAddressZipCode'){
      this.controlStatus.ResidenceAddressZipCode = true
    }
    if(controlName == 'EmailAddress'){
     this.controlStatus.EmailAddress = true
   }
   if(controlName == 'Phone'){
    this.controlStatus.Phone = true
  }
  if(controlName == 'WebsiteURL'){
    this.controlStatus.WebsiteURL = true
  }
  if(controlName == 'BillingAddressZipCode'){
    this.controlStatus.BillingAddressZipCode = true
  }
   }

   onFocusMethod(controlName){
     if(controlName == 'ResidenceAddressZipCode'){
       this.controlStatus.ResidenceAddressZipCode = false
     }
     if(controlName == 'EmailAddress'){
       this.controlStatus.EmailAddress = false
     }
     if(controlName == 'Phone'){
      this.controlStatus.Phone = false
    }
    if(controlName == 'WebsiteURL'){
      this.controlStatus.WebsiteURL = false
    }
    if(controlName == 'BillingAddressZipCode'){
      this.controlStatus.BillingAddressZipCode = false
    }
   }


  get f() { return this.companyForm.controls; }

  async onSubmit() {
    this.submitted = true;

    if(this.companyForm.controls['Name'].valid && this.companyForm.controls['KvkNumber'].valid && this.companyForm.controls['EmailAddress'].valid && this.companyForm.controls['ResidenceAddressStreet'].valid && this.companyForm.controls['ResidenceAddressStreetNumber'].valid && this.companyForm.controls['ResidenceAddressCity'].valid && this.companyForm.controls['ResidenceAddressZipCode'].valid && this.companyForm.controls['ResidenceAddressCountry'].valid){
      this.formValidate = true
    }else{
      this.formValidate = false
    }

    if (this.companyForm.invalid && !this.formValidate) {
      this._commonService.scrollToError();
      this.controlStatus.ResidenceAddressZipCode = true
      this.controlStatus.EmailAddress = true
      this.controlStatus.Phone = true
      this.controlStatus.WebsiteURL = true
      this.controlStatus.BillingAddressZipCode = true
      return;
    }else if(this.companyForm.invalid && this.formValidate && !this.isThirdCase){
      if((this.companyForm.controls['WebsiteURL'].errors && this.companyForm.controls['WebsiteURL'].errors.pattern)
      || (this.companyForm.controls['Phone'].errors && this.companyForm.controls['Phone'].errors.pattern)
      || (this.companyForm.controls['BillingAddressZipCode'].errors && this.companyForm.controls['BillingAddressZipCode'].errors.pattern)
      || (this.companyForm.controls['EmailAddress'].errors && this.companyForm.controls['EmailAddress'].errors.pattern)
      || (this.companyForm.controls['ResidenceAddressZipCode'].errors && this.companyForm.controls['ResidenceAddressZipCode'].errors.pattern)
      ){
      this.patternError = true
    }else{
      this.patternError = false
    }
  if(!this.patternError){
    this.openAlert()
  }

    }else{
      this.saveDetail()
  }
  }

  async saveDetail(){

    this._commonService.presentLoading();
    try {

      this.companies.Name = this.companyForm.value.Name;

      this.companies.KvkResidenceNumber = this.companyForm.value.KvkResidenceNumber;
      this.companies.KvkNumber = this.companyForm.value.KvkNumber;
      this.companies.EmailAddress = this.companyForm.value.EmailAddress.trim();
      this.companies.Phone = this.companyForm.value.Phone;
      this.companies.SbiCode1 = this.companyForm.value.SbiCode1;
      this.companies.SbiCode2 = this.companyForm.value.SbiCode2;
      this.companies.IsPrimaryCompany = this.companyForm.value.IsPrimaryCompany ? '1' : '0';
      this.companies.EstablishmentType = this.companyForm.value.EstablishmentType;
      this.companies.WebsiteURL = this.companyForm.value.WebsiteURL;
      this.companies.CompanyId = this.companyId ? this.companyId : null;

      this.companies.BillingAddressCountry = this.companyForm.value.BillingAddressCountry;
      this.companies.BillingAddressStreet = this.companyForm.value.BillingAddressStreet;
      this.companies.BillingAddressStreetNumber = this.companyForm.value.BillingAddressStreetNumber;
      this.companies.BillingAddressCity = this.companyForm.value.BillingAddressCity;
      this.companies.BillingAddressZipCode = this.companyForm.value.BillingAddressZipCode;
      this.companies.ResidenceAddressStreet = this.companyForm.value.ResidenceAddressStreet;
      this.companies.ResidenceAddressStreetNumber = this.companyForm.value.ResidenceAddressStreetNumber;
      this.companies.ResidenceAddressCity = this.companyForm.value.ResidenceAddressCity;
      this.companies.ResidenceAddressZipCode = this.companyForm.value.ResidenceAddressZipCode;
      this.companies.ResidenceAddressCountry = this.companyForm.value.ResidenceAddressCountry;
      this.companies.Representative = this.companyForm.value.Representative;
      this.companies.LegalFormId = this.companyForm.value.LegalFormId;
      let res = null;

      if(this.companies.Type=='contractcompany'){
        delete this.companies.CompanyId;
        this.dataType = 'contractcompanies';
        this.companies.ContractCompanyId = this.companyId ? this.companyId : null;
         res = await this._apiService.postContractCompany(this.companies);
      }else{
        this.dataType = 'companies';
         res = await this._apiService.postCompany(this.companies);
      }

      if (res) {
        let response = await this._apiService.checkCustomDataType(this.templateId, this.versionId, this.dataType, res.id)
        if (res.id && response.body.fields.length == 0) {
          this.companyId = res.id;
          await this.getCompanies();
          this.isThirdCase = false
          this.closeModal(false);
        }else{
          this.templateRequiredField = response.body.fields
          this.companyId = res.id;
          await this.getCompanies();
          await this.checkTemplateField();
          if(!this.isThirdCase){
            this.openAlert()
          }

        }
      }
      this._commonService.dismiss();
      this._commonService.showToast(this._commonService.getLocalTranslation('label-recordSaved'));
      this._commonService.log(this.companies, this._commonService.LOG);
      this.submitted = false;
      this.getCompanyDetails();
      // save to localstorage
      //this._commonService.getCompanies();
    } catch (error) {
      this.submitted = false;
      if(error.status >= 500 && error.status < 600){
        this.companyForm.patchValue({
          BillingAddressCity: this.tempCompanies.BillingAddressCity,
          BillingAddressCountry: this.tempCompanies.BillingAddressCountry,
          BillingAddressStreet: this.tempCompanies.BillingAddressStreet,
          BillingAddressStreetNumber: this.tempCompanies.BillingAddressStreetNumber,
          BillingAddressZipCode: this.tempCompanies.BillingAddressZipCode,
          // EmailAddress: this.companies.EmailAddress,
          ResidenceAddressStreet: this.tempCompanies.ResidenceAddressStreet,
          ResidenceAddressStreetNumber: this.tempCompanies.ResidenceAddressStreetNumber,
          ResidenceAddressCity: this.tempCompanies.ResidenceAddressCity,
          ResidenceAddressZipCode: this.tempCompanies.ResidenceAddressZipCode,
          ResidenceAddressCountry: this.tempCompanies.ResidenceAddressCountry,
          KvkNumber: this.tempCompanies.KvkNumber,
          KvkResidenceNumber: this.tempCompanies.KvkResidenceNumber,
          EmailAddress: this.tempCompanies.EmailAddress,
          IsPrimaryCompany: Number(this.tempCompanies.IsPrimaryCompany == '1') ? true : false,
          Name: this.tempCompanies.Name,
          EstablishmentType: this.tempCompanies.EstablishmentType,
          WebsiteURL: this.tempCompanies.WebsiteURL,
          Phone: this.tempCompanies.Phone,
          SbiCode1: this.tempCompanies.SbiCode1,
          SbiCode2: this.tempCompanies.SbiCode2,
          Representative: this.tempCompanies.Representative,
          LegalFormId: this.tempCompanies.LegalFormId,
        });

      }
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async openAlert(){

    setTimeout( async() => {
      let firstInvalidControl;
      firstInvalidControl = this.el.nativeElement.querySelector(
        "form .is-invalid"
      );
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });

      setTimeout( async() => {

        const alert = await this.alertController.create({
          header: '',
          message: this._commonService.getLocalTranslation('label-templateValidationError'),
          buttons: [
            {
              text: this._commonService.getLocalTranslation('label-ok'),
              handler: async () => {
                this.isThirdCase = true
                  await this.saveDetail();
                  this.closeModal(false);


              }
            },
            {
              text: this._commonService.getLocalTranslation('label-cancel'),
              role: 'cancel',
              handler: () => {
              }
            }


          ]
        });
        await alert.present();
      }, 200);
    }, 300);
  }

  onReset() {
    this.submitted = false;
    this.companyForm.reset();
  }

  onSelect(event) {
    let selected = [];
    event.selected.forEach(element => {
      selected.push(element.ObjectGroupId);
    });
    this.itemsSelected = selected;
  }


  onActivate(event) {
    if (event.type == 'click') {
      this.downloadFile(event.row.InoviceId, event.row.InoviceNumber)
    }
  }

  checkTemplateField(){
    if(this.templateRequiredField.length > 0){
      this.templateRequiredField.forEach(field => {
        Object.keys(this.companyForm.controls).forEach(key => {
            if(key.toLowerCase() == field){
              if(field == 'websiteurl'){
                this.companyForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.URLPattern)])
              }else if(field == 'emailaddress'){
                this.companyForm.controls[key].setValidators([Validators.required, Validators.email])
              }else if(field == 'phone'){
                this.companyForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.phonePattern)])
              }else if(field == 'billingaddresszipcode'){
                this.companyForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.postalCodePattern)])
              }else if(field == 'residenceaddresszipcode'){
                this.companyForm.controls[key].setValidators([Validators.required,Validators.pattern(this._commonService.postalCodePattern)])
              }else{
                this.companyForm.controls[key].setValidators(Validators.required)
              }

              this.companyForm.controls[key].setValidators(Validators.required)
              this.companyForm.controls[key].setErrors({'required': true})
              this.companyForm.controls[key].markAsTouched();
              this.companyForm.updateValueAndValidity()
              this.controlStatus.ResidenceAddressZipCode = true
              this.controlStatus.EmailAddress = true
              this.controlStatus.Phone = true
              this.controlStatus.WebsiteURL = true
              this.controlStatus.BillingAddressZipCode = true

            }
        });

      });

    }
  }


  changeLocale() {
    if (this.showPage) {
      if (this.companyId && this.companies.Translations) {
        let tempCompany = this.companies.Translations.find(x => x.LocaleId == this.tempLocale);
        tempCompany.Name = this.companyForm.value.Name;
        this.tempLocale = this.locale;
        let company = this.companies.Translations.find(x => x.LocaleId == this.locale);
        if (company) {
          this.companyForm.patchValue({
            Name: company.Name,
          });
        } else {
          this.companies.Translations.push({
            Name: '',
            Description: '',
            LocaleId: this.locale,
          });
          this.companyForm.controls['Name'].reset();
          // this.companyForm.controls['Description'].reset();
          // let textarea = document.querySelector('.textarea');
          // if (textarea) textarea.classList.remove("item-has-value");
        }
      } else {
        let tempCompany = this.companies.Translations.find(x => x.LocaleId == this.tempLocale);
        if (tempCompany) {
          tempCompany.Name = this.companyForm.value.Name;
          this.tempLocale = this.locale;
          let company = this.companies.Translations.find(x => x.LocaleId == this.locale);
          if (company) {
            this.companyForm.patchValue({
              Name: company.Name,
            });
          }
          else {
            this.companies.Translations.push({
              Name: '',
              Description: '',
              LocaleId: this.locale,
            });
            this.companyForm.controls['Name'].reset();
            // this.companyForm.controls['Description'].reset();
            // let textarea = document.querySelector('.textarea');
            // if (textarea) textarea.classList.remove("item-has-value");
            this.tempLocale = this.locale;
          }
        }
      }
    }
  }

  setPage(event){
    this.page.pageNumber = event.offset + 1;
  }

  async tabChanged(event){
    if(event.detail.value == 'invoice'){
      await this.getInvoiceList();
      setTimeout(() => {
        this.adjustColumnWidth()
        this.table.messages.emptyMessage = this._commonService.getLocalTranslation('label-itemUnavailable');
        if (!this.table.element.className.includes("border-header border-footer")) this.table.element.className += " border-header border-footer";
       }, 100);
    }
  }

  async downloadFile(invoiceId,  invoiceNumber){
    try {
      this._commonService.presentLoading();
      let data = await this._apiService.downloadInvoice(invoiceId);
      let blob = new Blob([data.body], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);;
      downloadLink.download = invoiceNumber + ".pdf";
      downloadLink.click();
      let newWindow = window.open('', "_blank");
      newWindow.location.href = URL.createObjectURL(blob);



      this._commonService.dismiss();
    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  async getLegalForm() {
    try {
      if (localStorage.LegalFormList) {
        this.LegalFormList  = JSON.parse(localStorage.LegalFormList);
      } else {
        let legalForm = await this._apiService.getLegalForm();
        this.LegalFormList = legalForm.body;
        localStorage.setItem('LegalFormList', JSON.stringify(legalForm.body));
      }

    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  onChangeBreadcrumb() {
    this.companies.Name = this.companyForm.value.Name;
  }

  async closeModal(closed) {
    let obj = {};
    if(this.companyId) {
      obj['id'] = this.companyId;
    }
    if(this.companyList && this.companyList.length > 0) {
      obj['list'] = this.companyList;
    }
    obj['closed'] = closed;
    this.modalController.dismiss(obj);
  }

  async getCompanies() {
    try {
      this._commonService.presentLoading();
      let res = await this._apiService.getCompaniesWizard();
      this.companyList = res.body;
      this._commonService.dismiss();
    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

  copyAddress(){
    this.companyForm.patchValue({
      BillingAddressCity: this.companyForm.get('ResidenceAddressCity').value,
      BillingAddressCountry: this.companyForm.get('ResidenceAddressCountry').value,
      BillingAddressStreet: this.companyForm.get('ResidenceAddressStreet').value,
      BillingAddressStreetNumber: this.companyForm.get('ResidenceAddressStreetNumber').value,
      BillingAddressZipCode: this.companyForm.get('ResidenceAddressZipCode').value
      });
  }

}
